import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { TextArea, Intent, FormGroup, InputGroup, Checkbox } from '@blueprintjs/core';
import { Toasts } from '../../components/toasts/Toaster';
import { Form } from '../../components/form/Form';
import { AsyncActionButton } from '../../components/async-action-button/AsyncActionButton';
import { sharedSandboxNotificationsStore, sharedNotificationsStore } from '../../stores/NotificationsStore';
import { TransportEnum } from '../../../sdks/server-sdk';

interface Props {

}

@observer
export class NotificationDispatch extends React.Component<Props> {
  @observable
  title = 'example';

  @observable
  detail = 'an example notification';

  @observable
  htmlContent = '<h1> not </h1>';

  @observable
  recipient = '';

  @observable
  phoneNumber = '';

  @observable
  email = '';

  @observable
  payload = '{}';

  @observable
  useSandbox = true;

  private sendNotification = async () => {
    const service = this.useSandbox ? sharedSandboxNotificationsStore : sharedNotificationsStore;
    await service.dispatch({
      title: this.title,
      detail: this.detail,
      htmlContent: this.htmlContent || undefined,
      payload: JSON.parse(this.payload),
      transports: [TransportEnum.Fcm, TransportEnum.Sms, TransportEnum.Email],
      recipients: [{
        username: this.recipient,
        phone: this.phoneNumber || undefined,
        email: this.email || undefined,
      }],
    });
    Toasts.show({ message: 'Notification sent', intent: Intent.SUCCESS });
  }

  render() {
    return (
      <div>
        <Form>
          <FormGroup label="Title" labelFor="title">
            <InputGroup
              id="title"
              placeholder="title"
              value={this.title}
              onChange={(event: any) => this.title = event.currentTarget.value}
            />
          </FormGroup>
          <FormGroup label="Detail" labelFor="detail">
            <TextArea
              id="detail"
              placeholder="detail"
              value={this.detail}
              onChange={(event: any) => this.detail = event.currentTarget.value}
            />
          </FormGroup>
          <FormGroup label="Html Content" labelFor="html_content">
            <TextArea
              id="html_content"
              placeholder="html content"
              value={this.htmlContent}
              onChange={(event: any) => this.htmlContent = event.currentTarget.value}
            />
          </FormGroup>
          <FormGroup label="Recipient" labelFor="recipient">
            <InputGroup
              id="recipient"
              placeholder="recipient"
              value={this.recipient}
              onChange={(event: any) => this.recipient = event.currentTarget.value}
            />
          </FormGroup>
          <FormGroup label="Phone Number" labelFor="phone">
            <InputGroup
              id="phone"
              placeholder="phone number"
              value={this.phoneNumber}
              onChange={(event: any) => this.phoneNumber = event.currentTarget.value}
            />
          </FormGroup>
          <FormGroup label="Email" labelFor="email">
            <InputGroup
              id="email"
              placeholder="email"
              value={this.email}
              onChange={(event: any) => this.email = event.currentTarget.value}
            />
          </FormGroup>
          <FormGroup label="Payload" labelInfo="(JSON formatted)" labelFor="payload">
            <TextArea
              id="payload"
              placeholder="payload"
              value={this.payload}
              onChange={((event: any) => this.payload = event.currentTarget.value)}
            />
          </FormGroup>
          <FormGroup>
            <Checkbox
              label="Use Sandbox?"
              name="use-sandbox"
              checked={this.useSandbox}
              onChange={(event) => this.useSandbox = event.currentTarget.checked}
            />
          </FormGroup>
          <AsyncActionButton
            type="submit"
            text="send notification"
            intent={Intent.SUCCESS}
            onClick={this.sendNotification}
          />
        </Form>
      </div>
    );
  }
}
