import { publicApiConfig } from '../api-config';
import { autoToastErrors } from '../decorators/auto-toast-errors';
import { TenantApi, TenantSandboxApi } from '../../sdks/tenants-sdk';
import { sharedTenantStore } from '../stores/TenantStore';

export class TenantService {

  private tenantApi = new TenantApi(publicApiConfig);
  private sandboxApi = new TenantSandboxApi(publicApiConfig);

  @autoToastErrors()
  async loadTenant() {
    sharedTenantStore.tenant = await this.tenantApi.retrieveTenant();
  }

  @autoToastErrors()
  async enforceWhitelist(whitelistEnabled: boolean) {
    sharedTenantStore.tenant = await this.tenantApi.updateTenant({ tenant: { whitelistEnabled } });
  }

  @autoToastErrors()
  async updateWhitelist(whitelist: string[]) {
    sharedTenantStore.tenant = await this.tenantApi.updateTenant({ tenant: { whitelist } });
  }

  @autoToastErrors()
  async enableSandbox() {
    sharedTenantStore.tenant!.sandbox = await this.sandboxApi.updateSandbox({ tenantSandbox: { enabled: true } });
  }

  @autoToastErrors()
  async disableSandbox() {
    sharedTenantStore.tenant!.sandbox = await this.sandboxApi.updateSandbox({ tenantSandbox: { enabled: false } });
  }

  @autoToastErrors()
  async updateSandboxWhitelist(whitelist: string[]) {
    sharedTenantStore.tenant!.sandbox = await this.sandboxApi.updateSandbox({ tenantSandbox: { whitelist } });
  }
}
