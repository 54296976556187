import React from 'react';
import { findDOMNode } from 'react-dom';
import ResizeObserver from 'resize-observer-polyfill';
import { resizeContainer, content } from './ResizeContainer.scss';
import classes from '../../utils/classes';


interface Size {
  width: number;
  height: number;
}

interface Props {
  className?: string;
  minHeight?: number;
  minWidth?: number;
  style?: React.CSSProperties;
  children(size: Size): React.ReactNode;
}

interface State {
  size: Size;
}

export class ResizeContainer extends React.Component<Props, State> {

  private mounted = false;

  state = {
    size: {
      width: 0,
      height: 0,
    },
  };

  private resizeObserver = new ResizeObserver((entries) => {
    const entry = entries[0];
    if (entry) {
      this.resize({
        height: entry.contentRect.height,
        width: entry.contentRect.width,
      });
    }
  });

  private resize(size: Size) {
    window.requestAnimationFrame(() =>
      window.requestAnimationFrame(() => this.mounted && this.setState({ size })));
  }

  componentDidMount() {
    this.mounted = true;
    const domNode = findDOMNode(this);
    if (domNode) {
      this.resizeObserver.observe(domNode as Element);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    this.resizeObserver.disconnect();
  }

  render() {
    const { width, height } = this.state.size;
    const { minWidth, minHeight } = this.props;

    const size = {
      width: Math.max(width, minWidth || 0),
      height: Math.max(height, minHeight || 0),
    };

    const style = this.props.style || {};

    return (
      <div style={{ minWidth, minHeight, ...style }} className={classes(resizeContainer, this.props.className)}>
        <div className={content}>{this.props.children(size)}</div>
      </div>
    );
  }
}
