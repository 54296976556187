import React from 'react';
import { link } from './Link.scss';
import { NavHashLink } from 'react-router-hash-link';
import classes from '../../utils/classes';
import { history } from '../../history';

interface LinkProps {
  to: string;
  label?: string;
  keepScrollPosition?: boolean;
  className?: string;
  children?: React.ReactNode;
  exact?: boolean;
}

export class Link extends React.Component<LinkProps> {

  private containsPageAnchor(linkHref: string) {
  return linkHref.includes('#');
  }

  private getHash(url: string) {
    const hash = url.split('#')[1] || '';
    return `#${hash}`;
  }

  private getPath(url: string) {
    return url.split('#')[0].split('?')[0] || '';
  }

  private isActive() {
    const { pathname, hash } = history.location;
    let isActive = this.getPath(this.props.to) === pathname;
    if (this.containsPageAnchor(this.props.to) && this.getHash(this.props.to) !== this.getHash(hash)) {
      isActive = false;
    }
    return isActive;
  }

  private isUncontrolled() {
    return this.props.keepScrollPosition === undefined;
  }

  private onClick = () => {
    if (this.props.keepScrollPosition === false) {
      return window.scrollTo(0, 0);
    }

    if (this.isUncontrolled()) {
      if (!this.containsPageAnchor(this.props.to)) {
        return window.scrollTo(0, 0);
      }

      if (this.getHash(this.props.to) === '#') {
        return document.body.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  render() {
    return (
      <NavHashLink
        to={this.props.to}
        className={classes(link, this.props.className, this.isActive() ? 'active' : undefined)}
        smooth
        exact={this.props.exact}
        onClick={this.onClick}
      >
        {this.props.label || this.props.children}
      </NavHashLink>
    );
  }
}
