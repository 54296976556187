// tslint:disable
/**
 * packetgrid-internal-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Recipient,
    RecipientFromJSON,
    RecipientToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateBrowserForm
 */
export interface CreateBrowserForm {
    /**
     * 
     * @type {string}
     * @memberof CreateBrowserForm
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateBrowserForm
     */
    origins: Array<string>;
    /**
     * 
     * @type {Array<Recipient>}
     * @memberof CreateBrowserForm
     */
    recipients: Array<Recipient>;
}

export function CreateBrowserFormFromJSON(json: any): CreateBrowserForm {
    return {
        'name': json['name'],
        'origins': json['origins'],
        'recipients': (json['recipients'] as Array<any>).map(RecipientFromJSON),
    };
}

export function CreateBrowserFormToJSON(value?: CreateBrowserForm): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'origins': value.origins,
        'recipients': (value.recipients as Array<any>).map(RecipientToJSON),
    };
}


