import { sharedAuthStore } from './stores/AuthStore';
import { Middleware, ResponseContext } from '../sdks/internal-sdk';
import { sharedTenantStore } from './stores/TenantStore';

export class InvalidTokenMiddleware implements Middleware {
  async post(context: ResponseContext) {
    if (context.response.status === 403) {
      const json = await context.response.json();
      if (json.detail.toLowerCase().startsWith('invalid token')) {
        sharedAuthStore.logout();
      }
    }
  }
}


export class TenantHeaderMiddleware implements Middleware {
  async pre(context: ResponseContext) {
    const tenant = sharedTenantStore.tenant;
    if (tenant) {
      context.init.headers = {
        ...context.init.headers,
        'Tenant-Id': tenant.tenantId,
      };
    }
  }
}
