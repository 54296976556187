import { NotificationsApi, Configuration, SendNotification } from '../../sdks/server-sdk';
import { autoToastErrors } from '../decorators/auto-toast-errors';
import { notificationsApiConfig, sandboxNotificationsApiConfig } from '../api-config';

class NotificationsStore {
  private notifications: NotificationsApi;

  constructor(config: Configuration) {
    this.notifications = new NotificationsApi(config);
  }

  @autoToastErrors()
  async dispatch(command: SendNotification) {
    await this.notifications.send({ command });
  }
}

export const sharedNotificationsStore = new NotificationsStore(notificationsApiConfig);
export const sharedSandboxNotificationsStore = new NotificationsStore(sandboxNotificationsApiConfig);
