// tslint:disable
/**
 * packetgrid-internal-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ApiKey
 */
export interface ApiKey {
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    key: string;
}

export function ApiKeyFromJSON(json: any): ApiKey {
    return {
        'id': json['id'],
        'comment': json['comment'],
        'createdAt': json['created_at'],
        'key': json['key'],
    };
}

export function ApiKeyToJSON(value?: ApiKey): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'comment': value.comment,
        'created_at': value.createdAt,
        'key': value.key,
    };
}


