// tslint:disable
/**
 * packetgrid-internal-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiKey,
    ApiKeyFromJSON,
    ApiKeyToJSON,
    CreateApiKey,
    CreateApiKeyFromJSON,
    CreateApiKeyToJSON,
} from '../models';

export interface CreateApiKeyRequest {
    apiKey?: CreateApiKey;
}

export interface DeleteApiKeyRequest {
    id: string;
}

/**
 * no description
 */
export class ApiKeysApi extends runtime.BaseAPI {

    /**
     * create a new ApiKey
     */
    async createApiKeyRaw(requestParameters: CreateApiKeyRequest): Promise<runtime.ApiResponse<ApiKey>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/api-keys/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateApiKeyToJSON(requestParameters.apiKey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiKeyFromJSON(jsonValue));
    }

    /**
     * create a new ApiKey
     */
    async createApiKey(requestParameters: CreateApiKeyRequest): Promise<ApiKey> {
        const response = await this.createApiKeyRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete a single ApiKey by id
     */
    async deleteApiKeyRaw(requestParameters: DeleteApiKeyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteApiKey.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/api-keys/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete a single ApiKey by id
     */
    async deleteApiKey(requestParameters: DeleteApiKeyRequest): Promise<void> {
        await this.deleteApiKeyRaw(requestParameters);
    }

    /**
     * list ApiKeys
     */
    async listApiKeysRaw(): Promise<runtime.ApiResponse<Array<ApiKey>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/api-keys/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiKeyFromJSON));
    }

    /**
     * list ApiKeys
     */
    async listApiKeys(): Promise<Array<ApiKey>> {
        const response = await this.listApiKeysRaw();
        return await response.value();
    }

}
