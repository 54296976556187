import React from 'react';
import { loading } from './Loading.scss';
import { Spinner, Intent } from '@blueprintjs/core';

interface Props {

}

export function Loading(props: Props) {
  return (
    <div className={loading}>
      <Spinner
        intent={Intent.SUCCESS}
        size={80}
      />
    </div>
  );
}
