// tslint:disable
/**
 * packetgrid-internal-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Recipient,
    RecipientFromJSON,
    RecipientToJSON,
} from './';

/**
 * 
 * @export
 * @interface BrowserForm
 */
export interface BrowserForm {
    /**
     * 
     * @type {string}
     * @memberof BrowserForm
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof BrowserForm
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BrowserForm
     */
    origins: Array<string>;
    /**
     * 
     * @type {Array<Recipient>}
     * @memberof BrowserForm
     */
    recipients: Array<Recipient>;
    /**
     * 
     * @type {string}
     * @memberof BrowserForm
     */
    createdAt: string;
}

export function BrowserFormFromJSON(json: any): BrowserForm {
    return {
        'slug': json['slug'],
        'name': json['name'],
        'origins': json['origins'],
        'recipients': (json['recipients'] as Array<any>).map(RecipientFromJSON),
        'createdAt': json['created_at'],
    };
}

export function BrowserFormToJSON(value?: BrowserForm): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'slug': value.slug,
        'name': value.name,
        'origins': value.origins,
        'recipients': (value.recipients as Array<any>).map(RecipientToJSON),
        'created_at': value.createdAt,
    };
}


