import React from 'react';
import { logo } from './Logo.scss';
import classes from '../../utils/classes';

interface Props {
  className?: string;
}

export class Logo extends React.Component<Props> {
  render() {
    return (
      <div className={classes(logo, this.props.className)}>
        <img
          src={require('./static/packet-grid-logo.png')}
          style={{
            objectFit: 'contain',
            width: '20em',
            maxWidth: '70%',
          }}
        />
      </div>
    );
  }
}
