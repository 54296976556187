import React from 'react';
import { Button, IButtonProps, Spinner } from '@blueprintjs/core';

type ButtonProps = IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

interface Props extends ButtonProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => Promise<void>;
}

export class AsyncActionButton extends React.Component<Props> {
  private mounted = false;

  state = {
    loading: false,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleClick = async (event: any) => {
    this.setState({ loading: true });
    try {
      if (this.props.onClick) {
        await this.props.onClick(event);
      }
    } finally {
      if (this.mounted) {
        this.setState({ loading: false });
      }
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <Button
        {...this.props}
        onClick={this.handleClick}
        text={loading ? <Spinner size={20} /> : this.props.text}
        disabled={loading ? true : this.props.disabled}
      />
    );
  }
}
