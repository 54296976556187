// tslint:disable
/**
 * packetgrid-server-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Recipient,
    RecipientFromJSON,
    RecipientToJSON,
    TransportEnum,
    TransportEnumFromJSON,
    TransportEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface SendNotification
 */
export interface SendNotification {
    /**
     * 
     * @type {string}
     * @memberof SendNotification
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SendNotification
     */
    detail: string;
    /**
     * 
     * @type {string}
     * @memberof SendNotification
     */
    htmlContent?: string;
    /**
     * 
     * @type {object}
     * @memberof SendNotification
     */
    payload: object;
    /**
     * 
     * @type {Array<Recipient>}
     * @memberof SendNotification
     */
    recipients: Array<Recipient>;
    /**
     * 
     * @type {Array<TransportEnum>}
     * @memberof SendNotification
     */
    transports: Array<TransportEnum>;
}

export function SendNotificationFromJSON(json: any): SendNotification {
    return {
        'title': json['title'],
        'detail': json['detail'],
        'htmlContent': !exists(json, 'html_content') ? undefined : json['html_content'],
        'payload': json['payload'],
        'recipients': (json['recipients'] as Array<any>).map(RecipientFromJSON),
        'transports': (json['transports'] as Array<any>).map(TransportEnumFromJSON),
    };
}

export function SendNotificationToJSON(value?: SendNotification): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'detail': value.detail,
        'html_content': value.htmlContent,
        'payload': value.payload,
        'recipients': (value.recipients as Array<any>).map(RecipientToJSON),
        'transports': (value.transports as Array<any>).map(TransportEnumToJSON),
    };
}


