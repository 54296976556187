import { Toasts } from '../components/toasts/Toaster';
import { Intent } from '@blueprintjs/core';

export function copyToClipboard(value: string) {
  Toasts.show({
    message: 'copied to clipboard',
    intent: Intent.SUCCESS,
  });
  const el = document.createElement('textarea');
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}
