// tslint:disable
/**
 * tenant
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Credentials,
    CredentialsFromJSON,
    CredentialsToJSON,
    Token,
    TokenFromJSON,
    TokenToJSON,
} from '../models';

export interface LoginRequest {
    credentials: Credentials;
}

/**
 * no description
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     */
    async loginRaw(requestParameters: LoginRequest): Promise<runtime.ApiResponse<Token>> {
        if (requestParameters.credentials === null || requestParameters.credentials === undefined) {
            throw new runtime.RequiredError('credentials','Required parameter requestParameters.credentials was null or undefined when calling login.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/token/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CredentialsToJSON(requestParameters.credentials),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
     */
    async login(requestParameters: LoginRequest): Promise<Token> {
        const response = await this.loginRaw(requestParameters);
        return await response.value();
    }

}
