// tslint:disable
/**
 * packetgrid-server-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TransportEnum {
    Email = 'email',
    Fcm = 'fcm',
    Apns = 'apns',
    Sms = 'sms'
}

export function TransportEnumFromJSON(json: any): TransportEnum {
    return json as TransportEnum;
}

export function TransportEnumToJSON(value?: TransportEnum): any {
    return value as any;
}

