/**
 * @see https://en.wikipedia.org/wiki/Memoization
 * @param {Function} fn - the function to be wrapped.
 */
export function memoize(fn: any): any {
  let hasRun = false;
  let result: any = undefined;
  return (...args: any[]) => {
    if (!hasRun) {
      result = fn(...args);
      hasRun = true;
    }
    return result;
  };
}
