// tslint:disable
/**
 * packetgrid-internal-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    DispatchReport,
    DispatchReportFromJSON,
    DispatchReportToJSON,
} from './';

/**
 * 
 * @export
 * @interface DispatchReportPage
 */
export interface DispatchReportPage {
    /**
     * 
     * @type {number}
     * @memberof DispatchReportPage
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof DispatchReportPage
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof DispatchReportPage
     */
    previous?: string;
    /**
     * 
     * @type {Array<DispatchReport>}
     * @memberof DispatchReportPage
     */
    results: Array<DispatchReport>;
}

export function DispatchReportPageFromJSON(json: any): DispatchReportPage {
    return {
        'count': json['count'],
        'next': !exists(json, 'next') ? undefined : json['next'],
        'previous': !exists(json, 'previous') ? undefined : json['previous'],
        'results': (json['results'] as Array<any>).map(DispatchReportFromJSON),
    };
}

export function DispatchReportPageToJSON(value?: DispatchReportPage): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': (value.results as Array<any>).map(DispatchReportToJSON),
    };
}


