
import React from 'react';
import { createPortal } from 'react-dom';

interface Props {
  style?: React.CSSProperties;
}

export class Portal extends React.Component<Props> {

  state = {
    root: undefined as HTMLDivElement | undefined,
  };

  componentDidMount() {
    this.setState({ root: document.createElement('div') }, () => {
      this.updatePortalRootStyles(this.props);
      document.body.appendChild(this.state.root!);
    });
  }

  componentWillUnmount() {
    document.body.removeChild(this.state.root!);
  }

  componentWillReceiveProps(nextProps: Props) {
    this.updatePortalRootStyles(nextProps);
  }

  updatePortalRootStyles = (props: Props) => {
    if (props.style) {
      for (const key of Object.keys(props.style)) {
        this.state.root!.style[key as any] = (props.style as any)[key];
      }
    }
  }

  render() {
    if (this.state.root) {
      return createPortal(this.props.children, this.state.root);
    }
    return null;
  }
}
