import React from 'react';
import { dashboard, sidebar, content } from './Dashboard.scss';
import { Link } from '../../components/link/Link';
import classes from '../../utils/classes';
import { column, flex1, row } from '../../common.scss';
import { Switch } from 'react-router';
import { NotificationDispatch } from '../notification-dispatch/NotificationDispatch';
import { AuthenticatedRoute } from '../../components/authenticated-route/AuthenticatedRoute';
import { TenantSettings } from '../tenant-settings/TenantSettings';
import { Overview } from '../overview/Overview';
import { preloadOnce } from '../../components/preload-once';
import { Sandbox } from '../sandbox/Sandbox';
import { PageNotFound } from '../../components/page-not-found/PageNotFound';
import { History } from '../history/History';
import { TenantService } from '../../services/TenantService';

async function loadTenant() {
  const service = new TenantService();
  await service.loadTenant();
}

@preloadOnce(loadTenant)
export class Dashboard extends React.Component {
  render() {
    return (
      <div className={classes(dashboard, row, flex1)}>
        <div className={classes(sidebar, column)}>
          <Link exact to="/dashboard/">Dashboard</Link>
          <Link exact to="/dashboard/history/">History</Link>
          <Link exact to="/dashboard/sandbox/">Sandbox</Link>
          <Link exact to="/dashboard/notifications/dispatch/">Dispatch</Link>
          <Link exact to="/dashboard/settings/">Settings</Link>
        </div>
        <div className={classes(content, column, flex1)}>
          <Switch>
            <AuthenticatedRoute exact path="/dashboard/" component={Overview} />
            <AuthenticatedRoute exact path="/dashboard/history/" component={History} />
            <AuthenticatedRoute exact path="/dashboard/sandbox/" component={Sandbox} />
            <AuthenticatedRoute exact path="/dashboard/notifications/dispatch/" component={NotificationDispatch} />
            <AuthenticatedRoute exact path="/dashboard/settings/" component={TenantSettings} />
            <AuthenticatedRoute exact path="/dashboard/*" component={PageNotFound} />
          </Switch>
        </div>
      </div>
    );
  }
}
