import { action, observable } from 'mobx';

type Identifier = string | number;

export default abstract class EntityStore<T> {

  protected abstract lookupField: Extract<keyof T, Identifier>;

  @observable
  protected entities = new Map<any, T>();

  constructor() {
    if (!this.entities) {
      // this fixes a bug that happens when a class extending
      // EntityStore wants to override `entities` using an
      // @computed getter.
      this.entities = new Map<Identifier, T>();
    }
  }

  get(key: Identifier) {
    return this.entities.get(key);
  }

  all() {
    return Array.from(this.entities.values());
  }

  filterBy<key extends keyof T>(key: key, value: T[key]) {
    return this.all().filter((entity) => entity[key] === value);
  }

  @action
  add(...entities: T[]) {
    entities.forEach((entity) => this.entities.set(entity[this.lookupField], entity));
  }

  delete(entity: T) {
    this.deleteById(entity[this.lookupField] as any);
  }

  deleteById(id: Identifier) {
    this.entities.delete(id);
  }
}
