import React from 'react';
import { Loading } from './loading/Loading';

interface AsyncContentProps {
  // children must be a async 'function as a child'. The function
  // must resolve with a renderable JSX element
  children(): Promise<JSX.Element | null | false>;

  // if the 'content' promise rejects (error) then this is the message
  // that should be rendered.
  errorMessage?: React.ReactNode;

  // the placeholder will be rendered while until the promise resolves.
  placeholder?: React.ReactNode;
}

interface State {
  content: React.ReactNode;
  failed: boolean;
}

class AsyncContent extends React.Component<AsyncContentProps, State> {

  static defaultProps = {
    errorMessage: 'Oops, something went wrong. Try checking your network connection.',
    placeholder: <Loading />,
  };

  state = {
    content: undefined,
    failed: false,
  };

  private mounted = false;

  componentDidMount() {
    this.mounted = true;
    this.setupContentPromise(this.props);
  }

  componentWillReceiveProps(nextProps: AsyncContentProps) {
    // reset our state when props change
    this.setState({ failed: false });

    // add promise handlers onto the content
    this.setupContentPromise(nextProps);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  private setupContentPromise(props: AsyncContentProps) {
    props.children()
      .then(this.onResolve)
      .catch(this.onReject);
  }

  private onResolve = (content: React.ReactNode) => {
    if (this.mounted) {
      this.setState({ content });
    }
  }

  private onReject = (error: any) => {
    console.error(error); // tslint:disable-line no-console
    if (this.mounted) {
      this.setState({ failed: true });
    }
  }

  render() {
    const { content, failed } = this.state;
    const { errorMessage, placeholder } = this.props;

    if (failed) {
      return <div>{errorMessage}</div>;
    }

    if (content === undefined) {
      return placeholder as any;
    }

    return content;
  }
}

export default AsyncContent;
