import { Toaster, Position, IToastProps } from '@blueprintjs/core';
import { capitalize } from '../util/text-utils';

// tslint:disable-next-line variable-name
export const Toasts = Toaster.create({ position: Position.TOP });

const show = Toasts.show.bind(Toasts);
Toasts.show = (props: IToastProps, key?: string) => {
  // avoid duplicate messages by setting key as the message.
  const message = typeof(props.message) === 'string' ? capitalize(props.message) : props.message;
  return show({ ...props, message }, key || (props.message as string).toString());
};
