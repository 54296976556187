// tslint:disable
/**
 * tenant
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface TenantSandbox
 */
export interface TenantSandbox {
    /**
     * 
     * @type {string}
     * @memberof TenantSandbox
     */
    primaryDomainUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantSandbox
     */
    enabled: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantSandbox
     */
    whitelist: Array<string>;
}

export function TenantSandboxFromJSON(json: any): TenantSandbox {
    return {
        'primaryDomainUrl': json['primary_domain_url'],
        'enabled': json['enabled'],
        'whitelist': json['whitelist'],
    };
}

export function TenantSandboxToJSON(value?: TenantSandbox): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'primary_domain_url': value.primaryDomainUrl,
        'enabled': value.enabled,
        'whitelist': value.whitelist,
    };
}


