import React from 'react';
import { displayStyle, content, actions } from './Display.scss';
import { render, unmountComponentAtNode } from 'react-dom';
import { Dialog, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

interface Props {
  onClosed(): void;
}

class Display extends React.Component<Props> {

  state = { isOpen: true };

  close = () => {
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <Dialog
        className={displayStyle}
        isOpen={this.state.isOpen}
        onClose={this.close}
        onClosed={() => this.props.onClosed()}
        canEscapeKeyClose
        canOutsideClickClose
      >
        <div className={actions}>
          <Icon
            icon={IconNames.CROSS}
            onClick={this.close}
            iconSize={23}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className={content}>
          {this.props.children}
        </div>
      </Dialog>
    );
  }
}

export async function display(node: React.ReactNode) {
  return new Promise((resolve) => {
    const root = document.createElement('div');
    document.body.append(root);
    render(
      (
        <Display
          onClosed={() => {
            unmountComponentAtNode(root);
            resolve();
          }}
        >
          {node}
        </Display>
      ),
      root,
    );
  });
}
