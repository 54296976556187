import EntityStore from './EntityStore';
import { internalNotificationsApiConfig, internalSandboxNotificationsApiConfig } from '../api-config';
import { autoToastErrors } from '../decorators/auto-toast-errors';
import { ApiKey, ApiKeysApi, Configuration, CreateApiKey } from '../../sdks/internal-sdk';

export class ApiKeyStore extends EntityStore<ApiKey> {
  protected lookupField = 'id' as 'id';

  private apikeys: ApiKeysApi;

  constructor(config: Configuration) {
    super();
    this.apikeys = new ApiKeysApi(config);
  }

  @autoToastErrors()
  async loadKeys() {
    const keys = await this.apikeys.listApiKeys({});
    this.add(...keys);
  }

  @autoToastErrors()
  async createKey(apiKey: CreateApiKey) {
    const key = await this.apikeys.createApiKey({ apiKey });
    this.add(key);
  }

  @autoToastErrors()
  async deleteKey(id: string) {
    await this.apikeys.deleteApiKey({ id });
    this.deleteById(id);
  }
}

export const sharedApiKeyStore = new ApiKeyStore(internalNotificationsApiConfig);
export const sharedSandboxApiKeyStore = new ApiKeyStore(internalSandboxNotificationsApiConfig);
