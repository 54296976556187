import EntityStore from './EntityStore';
import { autoToastErrors } from '../decorators/auto-toast-errors';
import { DispatchReport, ApiUsageApi } from '../../sdks/internal-sdk';
import { internalNotificationsApiConfig } from '../api-config';

class DispatchReportsStore extends EntityStore<DispatchReport> {
  lookupField = 'id' as 'id';

  private api = new ApiUsageApi(internalNotificationsApiConfig);

  @autoToastErrors()
  async loadReports() {
    const reports = await this.api.listDispatchReports({});
    this.add(...reports.results);
  }
}

export const sharedDispatchReportsStore = new DispatchReportsStore();
