import React from 'react';
import { Alert, Intent, Icon } from '@blueprintjs/core';
import { render, unmountComponentAtNode } from 'react-dom';
import { IconNames } from '@blueprintjs/icons';
import classes from '../../utils/classes';
import { row } from '../../common.scss';

interface Props {
  message: React.ReactNode;
  intent?: Intent;
  onConfirm: () => void;
  onCancel: () => void;
}

class Prompt extends React.Component<Props> {

  state = {
    isOpen: true,
  };

  close = (callback: () => void) => {
    this.setState({ isOpen: false }, () => {
      setTimeout(() => requestAnimationFrame(callback), 300);
    });
  }

  render() {
    return (
      <div>
        <Alert
          confirmButtonText="Ok"
          onConfirm={() => this.close(this.props.onConfirm)}
          cancelButtonText="Cancel"
          onCancel={() => this.close(this.props.onCancel)}
          isOpen={this.state.isOpen}
          intent={this.props.intent}
        >
          {typeof(this.props.message) === 'string' ? (
            <div className={classes(row)}>
              <Icon
                icon={IconNames.INFO_SIGN}
                intent={this.props.intent}
                iconSize={25}
              />
              <p>
                {this.props.message}
              </p>
            </div>
          ) : (
            this.props.message
          )}
        </Alert>
      </div>
    );
  }
}

interface PromptOptions {
  message: string | React.ReactNode;
  intent?: Intent;
}

export async function promptUser(props: PromptOptions) {
  return new Promise<boolean>((resolve) => {
    const root = document.createElement('div');
    document.body.append(root);
    render(
      (
        <Prompt
          message={props.message}
          intent={props.intent}
          onConfirm={() => {
            unmountComponentAtNode(root);
            resolve(true);
          }}
          onCancel={() => {
            unmountComponentAtNode(root);
            resolve(false);
          }}
        />
      ),
      root,
    );
  });
}

export async function confirmDangerousAction(message: string) {
  return await promptUser({ message, intent: Intent.DANGER });
}
