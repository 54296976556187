import React from 'react';
import { Intent } from '@blueprintjs/core';
import { Toasts } from '../components/toasts/Toaster';

export async function toastServiceError(error: Error | Response) {
  if (error instanceof Response) {
    const message = errorJsonToMessage(await error.json());
    Toasts.show({ message, intent: Intent.DANGER });
  } else {
    Toasts.show({
      message: 'An unknown error occurred. We\'ve dispatched our highly trained team of bananas to fix your issue.',
      intent: Intent.DANGER,
    });
  }
}

function errorJsonToMessage(json: any) {
  if ('detail' in json) {
    return json.detail;
  }
  if ('non_field_errors' in json) {
    return json.non_field_errors;
  }
  const errors = new Array<string>();
  for (const [ key, value ] of Object.entries(json)) {
    const message = Array.isArray(value) ? value.join(', ') : value;
    errors.push(`${key}: ${message}`);
  }
  if (errors.length === 1) {
    return errors[0];
  }
  return (
    <ul style={{ margin: 0 }}>
      {errors.map((message, i) => <li key={i}>{message}</li>)}
    </ul>
  );
}
