import React from 'react';
import { drawer, root, backdrop, drawerLeft, drawerRight } from './Drawer.scss';
import { spring, Motion, SpringHelperConfig } from 'react-motion';
import classes from '../../utils/classes';
import { Portal } from '../portal/Portal';

export enum Side {
  left = drawerLeft,
  right = drawerRight,
}

interface Props {
  isOpen: boolean;
  onRequestClose?: () => void;
  side?: Side;
  className?: string;
}

export class Drawer extends React.Component<Props> {

  static defaultProps = {
    side: Side.left,
  };

  springConfig: SpringHelperConfig = {
    stiffness: 200,
    damping: 18,
  };

  render() {
    const offset = this.props.side === Side.right ? 100 : -100;
    return (
      <Portal style={{ pointerEvents: this.props.isOpen ? 'all' : 'none' }}>
        <Motion
          style={{
            translation: spring(this.props.isOpen ? 0 : offset, this.springConfig),
            opacity: spring(this.props.isOpen ? 1 : 0, this.springConfig),
          }}
        >
          {({ translation, opacity }) =>
            <div className={classes(root)}>
              <div
                className={classes(drawer, String(this.props.side))}
                style={{ transform: `translateX(${translation}%)` }}
              >
                {this.props.children}
              </div>
              <div
                onClick={this.props.onRequestClose}
                className={backdrop}
                style={{ opacity }}
              />
            </div>
          }
        </Motion>
      </Portal>
    );
  }
}
