// tslint:disable
/**
 * tenant
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Tenant,
    TenantFromJSON,
    TenantToJSON,
    User,
    UserFromJSON,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface TenantUser
 */
export interface TenantUser {
    /**
     * 
     * @type {string}
     * @memberof TenantUser
     */
    id: string;
    /**
     * 
     * @type {User}
     * @memberof TenantUser
     */
    user: User;
    /**
     * 
     * @type {Tenant}
     * @memberof TenantUser
     */
    tenant: Tenant;
}

export function TenantUserFromJSON(json: any): TenantUser {
    return {
        'id': json['id'],
        'user': UserFromJSON(json['user']),
        'tenant': TenantFromJSON(json['tenant']),
    };
}

export function TenantUserToJSON(value?: TenantUser): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'user': UserToJSON(value.user),
        'tenant': TenantToJSON(value.tenant),
    };
}


