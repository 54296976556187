// tslint:disable
/**
 * packetgrid-server-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * it is a token for the mobile device to make requests to Packergrid
 * @export
 * @interface ClientToken
 */
export interface ClientToken {
    /**
     * 
     * @type {string}
     * @memberof ClientToken
     */
    token: string;
    /**
     * 
     * @type {Date}
     * @memberof ClientToken
     */
    expiryDate?: Date;
}

export function ClientTokenFromJSON(json: any): ClientToken {
    return {
        'token': json['token'],
        'expiryDate': !exists(json, 'expiry_date') ? undefined : new Date(json['expiry_date']),
    };
}

export function ClientTokenToJSON(value?: ClientToken): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'token': value.token,
        'expiry_date': value.expiryDate === undefined ? undefined : value.expiryDate.toISOString(),
    };
}


