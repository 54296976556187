import React from 'react';

interface Props extends React.FormHTMLAttributes<HTMLFormElement> {

}

export class Form extends React.Component<Props> {

  private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.props.onSubmit) {
      this.props.onSubmit(event);
    } else {
      const button = event.currentTarget.querySelector('button[type="submit"]') as HTMLButtonElement;
      if (button) {
        button.click();
      }
    }
  }

  render() {
    return (
      <form {...this.props} onSubmit={this.handleSubmit}>
        {this.props.children}
      </form>
    );
  }
}
