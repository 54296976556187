import React from 'react';
import { revealableValue } from './RevealableValue.scss';
import { Button } from '@blueprintjs/core';
import classes from '../../utils/classes';
import { row, alignCenter } from '../../common.scss';
import { CopiableValue } from '../copiable-value/CopiableValue';

interface Props {
  value?: string;
}

export class RevealableValue extends React.Component<Props> {

  state = {
    isVisible: false,
  };

  render() {
    return (
      <div className={classes(revealableValue, row, alignCenter)}>
        <CopiableValue
          value={this.props.value || ''}
          type={this.state.isVisible ? 'text' : 'password'}
        />
        <Button text="reveal" onClick={() => this.setState({ isVisible: !this.state.isVisible })} />
      </div>
    );
  }
}
