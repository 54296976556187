import React from 'react';
import {
  inner,
  wrapper,
  style1,
  actions,
  style2,
  spotlights,
  content,
  button,
  split,
  style1Alt,
  menu,
  homePage,
  style3,
  fields,
  field,
  half,
  message,
  submit,
  contentWrapper,
  footer,
} from './home-page/assets/sass/main.scss';
import { iphone, hero, howItWorks, features } from './Home.scss';
import classes from '../../utils/classes';
import { Link } from '../../components/link/Link';
import { Icon, Intent } from '@blueprintjs/core';
import { Logo } from '../../components/logo/Logo';
import { IconNames } from '@blueprintjs/icons';
import { HomeNavigation } from './home-navigation/HomeNavigation';
import { Toasts } from '../../components/toasts/Toaster';

// This component is from an HTML5UP template
// tslint:disable-next-line no-big-function
export function Home() {
  const formRef = React.createRef<HTMLFormElement>();

  async function submitForm(event: React.MouseEvent) {
    event.preventDefault();
    if (formRef.current && formRef.current.checkValidity()) {
      window
        .fetch('https://api.packetgrid.io/api/forms/contact/submit/', {
          method: 'POST',
          headers: { 'Tenant-Id': '990d1515d86d46128e39d6aebf031c68' },
          body: new FormData(formRef.current),
        })
        .then(() => {
          Toasts.show({
            message: 'You enquiry has been sent and we will contact you soon',
            intent: Intent.SUCCESS,
          });
          formRef.current!.reset();
        })
        .catch(() => {
          Toasts.show({
            message: 'Oops, Please try again later.',
            intent: Intent.DANGER,
          });
        });
    }
  }

  return (
    <div id={homePage}>
      <HomeNavigation />
      <div className={contentWrapper}>
        <section id="welcome" className={classes(hero, wrapper, style1)}>
          <div className={inner}>
            <Logo />
            <p>
              A powerful and flexible notifications service designed with
              developers in mind.
              <br />
              Send SMSs, emails, push notifications and more.
            </p>
            <ul className={actions}>
              <li>
                <Link to="/#how-it-works" className={button}>
                  How it works
                </Link>
              </li>
              <li>
                <Link to="/#features" className={button}>
                  See the features
                </Link>
              </li>
            </ul>
          </div>
          <img
            className={classes(iphone)}
            src={require('./static/iphone.png')}
            style={{ objectFit: 'contain' }}
          />
        </section>

        <section
          id="how-it-works"
          className={classes(howItWorks, wrapper, style2, spotlights)}
        >
          <section>
            <div className={content}>
              <div className={inner}>
                <h2>Set up all your notifications and channels</h2>
                <p>
                  Set up as many notifications across as many channels as you
                  need.
                </p>
              </div>
            </div>
          </section>
          <section>
            <div className={content}>
              <div className={inner}>
                <h2>Easily change your configuration</h2>
                <p>
                  Need a new notification or to change where something is going?
                  Not a problem with Packet Grid.
                </p>
              </div>
            </div>
          </section>
          <section>
            <div className={content}>
              <div className={inner}>
                <h2>Measure your results</h2>
                <p>
                  Beautiful, useful analytics that chart how many users are
                  seeing and interacting with your notifications
                </p>
              </div>
            </div>
          </section>
        </section>

        <section id="features" className={classes(wrapper, style3)}>
          <div className={inner}>
            <h2>Features</h2>
            <p>
              We've built Packet Grid to be a one-stop shop for all of your
              notification needs.
            </p>
            <div className={features}>
              <section>
                <Icon icon={IconNames.NOTIFICATIONS} />
                <h3>Lots of notification channels</h3>
                <p>
                  Notify anyone on any device. Send SMSs, emails, iOS and
                  Android push notifications and more.
                </p>
              </section>
              <section>
                <Icon icon={IconNames.DESKTOP} />
                <h3>A single dashboard</h3>
                <p>
                  Manage all of your notifications and their channels from one
                  dashboard, with custom configuration and analytics.
                </p>
              </section>
              <section>
                <Icon icon={IconNames.VERTICAL_BAR_CHART_ASC} />
                <h3>Scalable to your needs</h3>
                <p>
                  Packet Grid is flexible enough to work with large, complex
                  online apps with enterprise-level requirements.
                </p>
              </section>
              <section>
                <Icon icon={IconNames.CODE} />
                <h3>Developer friendly</h3>
                <p>
                  Build with Packet Grid's REST API or pick up an SDK for
                  Javascript, Swift, Java,{'\u00A0'}.NET, Python, Golang and
                  more.
                </p>
              </section>
            </div>
            <ul className={classes(actions)}>
              <li>
                <Link to="/#contact" className={button}>
                  Get Packet Grid
                </Link>
              </li>
            </ul>
          </div>
        </section>

        <section id="contact" className={classes(wrapper, style1)}>
          <div className={inner}>
            <h2>Get Packet Grid</h2>
            <p>
              Send us a message and we'll personally respond with the next
              steps.
            </p>
            <div className={classes(split, style1)}>
              <section>
                <form method="post" ref={formRef}>
                  <div className={fields}>
                    <div className={classes(field, half)}>
                      <label htmlFor="name">Name</label>
                      <input type="text" name="name" id="name" />
                    </div>
                    <div className={classes(field, half)}>
                      <label htmlFor="email">Email</label>
                      <input type="text" name="email" id="email" />
                    </div>
                    <div className={field}>
                      <label htmlFor="message">Message</label>
                      <textarea name="message" id={message} rows={5} />
                    </div>
                  </div>
                  <ul className={actions}>
                    <li>
                      <button
                        type="submit"
                        className={classes(button, submit)}
                        onClick={submitForm}
                      >
                        Send Message
                      </button>
                    </li>
                  </ul>
                </form>
              </section>
            </div>
          </div>
        </section>
      </div>

      <footer
        id={footer}
        className={classes(contentWrapper, wrapper, style1Alt)}
      >
        <div className={inner}>
          <ul className={menu}>
            <li>
              Design: <a href="http://html5up.net">HTML5 UP</a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
}
