export function capitalize(text: string) {
  if (text.length === 0) {
    return text;
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function longestCommonPrefix(strings: string[]) {
  const sorted = strings.concat().sort();
  const first = sorted[0];
  const last = sorted[sorted.length - 1];
  const length = first.length;
  let i = 0;
  while (i < length && first.charAt(i) === last.charAt(i)) {
    i++;
  }
  return first.substring(0, i);
}
