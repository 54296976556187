import React from 'react';
import { toggleApiSandboxButton } from './ToggleApiSandboxButton.scss';
import { Button, Intent } from '@blueprintjs/core';
import { sharedTenantStore } from '../../stores/TenantStore';
import { confirmDangerousAction } from '../prompt/Prompt';
import { TenantService } from '../../services/TenantService';

interface Props {

}

export class ToggleApiSandboxButton extends React.Component<Props> {

  private tenantService = new TenantService();

  isEnabled() {
    return sharedTenantStore.tenant!.sandbox.enabled;
  }

  private toggle = async () => {
    if (this.isEnabled()) {
      if (await confirmDangerousAction('Are you sure you want to disable the sandbox API?')) {
        await this.tenantService.disableSandbox();
      }
    } else {
      await this.tenantService.enableSandbox();
    }
  }

  render() {
    return (
      <div className={toggleApiSandboxButton}>
        <Button
          intent={this.isEnabled() ? Intent.DANGER : Intent.SUCCESS}
          onClick={this.toggle}
          large={!this.isEnabled()}
        >
          {this.isEnabled() ? 'Disable API Sandbox' : 'Enable'}
        </Button>
      </div>
    );
  }
}
