// tslint:disable
/**
 * packetgrid-internal-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiUsage,
    ApiUsageFromJSON,
    ApiUsageToJSON,
    DispatchReportPage,
    DispatchReportPageFromJSON,
    DispatchReportPageToJSON,
} from '../models';

export interface RetrieveOverviewRequest {
    startDate?: string;
    endDate?: string;
}

/**
 * no description
 */
export class ApiUsageApi extends runtime.BaseAPI {

    /**
     * list DispatchReports
     */
    async listDispatchReportsRaw(): Promise<runtime.ApiResponse<DispatchReportPage>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/usage/dispatch-reports/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DispatchReportPageFromJSON(jsonValue));
    }

    /**
     * list DispatchReports
     */
    async listDispatchReports(): Promise<DispatchReportPage> {
        const response = await this.listDispatchReportsRaw();
        return await response.value();
    }

    /**
     * retrieve api usage overview
     */
    async retrieveOverviewRaw(requestParameters: RetrieveOverviewRequest): Promise<runtime.ApiResponse<ApiUsage>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = requestParameters.endDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/usage/overview/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiUsageFromJSON(jsonValue));
    }

    /**
     * retrieve api usage overview
     */
    async retrieveOverview(requestParameters: RetrieveOverviewRequest): Promise<ApiUsage> {
        const response = await this.retrieveOverviewRaw(requestParameters);
        return await response.value();
    }

}
