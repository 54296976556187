import React from 'react';
import { IconNames } from '@blueprintjs/icons';
import { Icon } from '@blueprintjs/core';
import { Drawer, Side } from '../drawer/Drawer';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Col } from '../flexbox/Col';
import { Gutter } from '../flexbox/FlexProps';

interface Props {

}

@observer
export class HamburgerMenu extends React.Component<Props> {

  @observable
  isOpen = false;

  render() {
    return (
      <>
        <Icon
          icon={IconNames.MENU}
          onClick={() => this.isOpen = !this.isOpen}
        />
        <Drawer
          isOpen={this.isOpen}
          onRequestClose={() => this.isOpen = false}
          side={Side.right}
        >
          <Col gutter={Gutter.small} alignItems="center" style={{ paddingTop: 50 }}>
            {this.props.children}
          </Col>
        </Drawer>
      </>
    );
  }
}
