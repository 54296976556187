import { toastServiceError } from '../utils/toast-service-error';

export function autoToastErrors() {
  return (target: Object, propertyKey: string | symbol, descriptor: TypedPropertyDescriptor<any>) => {
    return {
      ...descriptor,
      value: function (this: any, ...args: any[]) {
        let result;
        try {
          result = descriptor.value.apply(this, args);
          if (result instanceof Promise) {
            result.catch((error) => {
              toastServiceError(error);
              throw error;
            });
          }
        } catch (error) {
          toastServiceError(error);
          throw error;
        } finally {
          return result;
        }
      },
    };
  };
}
