import React from 'react';
import { overview } from './Overview.scss';
import { ResizeContainer } from '../../components/resize-container/ResizeContainer';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  BarChart,
  Legend,
  Bar,
} from 'recharts';
import classes from '../../utils/classes';
import { column } from '../../common.scss';
import { Colors } from '@blueprintjs/core';
import { Metric } from '../../components/metric/Metric';
import { observer } from 'mobx-react';
import { preloadOnce } from '../../components/preload-once';
import { sharedApiUsageStore } from '../../stores/ApiUsageStore';
import { toJS } from 'mobx';
import { Col } from '../../components/flexbox/Col';
import { Gutter } from '../../components/flexbox/FlexProps';
import { Row } from '../../components/flexbox/Row';
import { mapBackendIdToDisplayName } from '../../utils/displayNames';

interface Props {}

@preloadOnce(async () => await sharedApiUsageStore.loadOverview())
@observer
export class Overview extends React.Component<Props> {
  render() {
    const usage = toJS(sharedApiUsageStore.overview!);
    return (
      <div className={classes(overview)}>
        <h1>Overview</h1>
        <Col gutter={Gutter.medium}>
          <Row gutter={Gutter.small} flexWrap="wrap" justifyContent="space-around">
            <div>
              <Metric
                label="Total Dispatch Requests"
                value={usage.totalDispatchRequests.toLocaleString()}
                color={Colors.GOLD2}
              />
            </div>
            <div>
              <Metric
                label="Min Recipients in a dispatch"
                value={usage.minRecipients.toLocaleString()}
                color={Colors.GOLD2}
              />
            </div>
            <div>
              <Metric
                label="Avg Recipients in a dispatch"
                value={usage.avgRecipients.toLocaleString()}
                color={Colors.GOLD2}
              />
            </div>
            <div>
              <Metric
                label="Max Recipients in a dispatch"
                value={usage.minRecipients.toLocaleString()}
                color={Colors.GOLD2}
              />
            </div>
          </Row>
          <div className={classes(column)}>
            <h2>Recent Activity</h2>
            <ResizeContainer minHeight={350}>
              {({ width, height }) => (
                <LineChart width={width} height={height} data={usage.activity}>
                  <Line
                    dataKey="value"
                    type="monotone"
                    stroke="#4285f4"
                    strokeWidth={3}
                  />
                  <XAxis dataKey="time" />
                  <YAxis />
                  <Tooltip />
                  <CartesianGrid strokeDasharray="3 3" />
                </LineChart>
              )}
            </ResizeContainer>
          </div>
          <div className={classes(column)}>
            <h2>Transport Utilization</h2>
            <ResizeContainer minHeight={350}>
              {({ width, height }) => (
                <BarChart
                  width={width}
                  height={height}
                  data={usage.transportUsage.map((item) => {
                    item.name = mapBackendIdToDisplayName(item.name);
                    return item;
                  })}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="value" fill="#8884d8" />
                </BarChart>
              )}
            </ResizeContainer>
          </div>
        </Col>
      </div>
    );
  }
}
