// tslint:disable
/**
 * packetgrid-internal-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ApiUsageActivity,
    ApiUsageActivityFromJSON,
    ApiUsageActivityToJSON,
    ApiUsageTransportUsage,
    ApiUsageTransportUsageFromJSON,
    ApiUsageTransportUsageToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiUsage
 */
export interface ApiUsage {
    /**
     * 
     * @type {number}
     * @memberof ApiUsage
     */
    totalDispatchRequests: number;
    /**
     * 
     * @type {number}
     * @memberof ApiUsage
     */
    minRecipients: number;
    /**
     * 
     * @type {number}
     * @memberof ApiUsage
     */
    maxRecipients: number;
    /**
     * 
     * @type {number}
     * @memberof ApiUsage
     */
    avgRecipients: number;
    /**
     * 
     * @type {Array<ApiUsageActivity>}
     * @memberof ApiUsage
     */
    activity: Array<ApiUsageActivity>;
    /**
     * 
     * @type {Array<ApiUsageTransportUsage>}
     * @memberof ApiUsage
     */
    transportUsage: Array<ApiUsageTransportUsage>;
}

export function ApiUsageFromJSON(json: any): ApiUsage {
    return {
        'totalDispatchRequests': json['total_dispatch_requests'],
        'minRecipients': json['min_recipients'],
        'maxRecipients': json['max_recipients'],
        'avgRecipients': json['avg_recipients'],
        'activity': (json['activity'] as Array<any>).map(ApiUsageActivityFromJSON),
        'transportUsage': (json['transport_usage'] as Array<any>).map(ApiUsageTransportUsageFromJSON),
    };
}

export function ApiUsageToJSON(value?: ApiUsage): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'total_dispatch_requests': value.totalDispatchRequests,
        'min_recipients': value.minRecipients,
        'max_recipients': value.maxRecipients,
        'avg_recipients': value.avgRecipients,
        'activity': (value.activity as Array<any>).map(ApiUsageActivityToJSON),
        'transport_usage': (value.transportUsage as Array<any>).map(ApiUsageTransportUsageToJSON),
    };
}


