// tslint:disable
/**
 * packetgrid-internal-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BrowserForm,
    BrowserFormFromJSON,
    BrowserFormToJSON,
    CreateBrowserForm,
    CreateBrowserFormFromJSON,
    CreateBrowserFormToJSON,
} from '../models';

export interface CreateBrowserFormRequest {
    browserForm?: CreateBrowserForm;
}

export interface DeleteBrowserFormRequest {
    slug: string;
}

export interface SubmitRequest {
    slug: string;
    browserForm: object;
}

/**
 * no description
 */
export class BrowserFormsApi extends runtime.BaseAPI {

    /**
     * create a new BrowserForm
     */
    async createBrowserFormRaw(requestParameters: CreateBrowserFormRequest): Promise<runtime.ApiResponse<BrowserForm>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/forms/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBrowserFormToJSON(requestParameters.browserForm),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BrowserFormFromJSON(jsonValue));
    }

    /**
     * create a new BrowserForm
     */
    async createBrowserForm(requestParameters: CreateBrowserFormRequest): Promise<BrowserForm> {
        const response = await this.createBrowserFormRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete a single BrowserForm by id
     */
    async deleteBrowserFormRaw(requestParameters: DeleteBrowserFormRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling deleteBrowserForm.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/forms/{slug}/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete a single BrowserForm by id
     */
    async deleteBrowserForm(requestParameters: DeleteBrowserFormRequest): Promise<void> {
        await this.deleteBrowserFormRaw(requestParameters);
    }

    /**
     * list BrowserForms
     */
    async listBrowserFormsRaw(): Promise<runtime.ApiResponse<Array<BrowserForm>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/forms/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BrowserFormFromJSON));
    }

    /**
     * list BrowserForms
     */
    async listBrowserForms(): Promise<Array<BrowserForm>> {
        const response = await this.listBrowserFormsRaw();
        return await response.value();
    }

    /**
     * submit a browser form
     */
    async submitRaw(requestParameters: SubmitRequest): Promise<runtime.ApiResponse<BrowserForm>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling submit.');
        }

        if (requestParameters.browserForm === null || requestParameters.browserForm === undefined) {
            throw new runtime.RequiredError('browserForm','Required parameter requestParameters.browserForm was null or undefined when calling submit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/forms/{slug}/submit/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.browserForm as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BrowserFormFromJSON(jsonValue));
    }

    /**
     * submit a browser form
     */
    async submit(requestParameters: SubmitRequest): Promise<BrowserForm> {
        const response = await this.submitRaw(requestParameters);
        return await response.value();
    }

}
