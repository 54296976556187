import React from 'react';
import { Routes } from './routes';
import { observer } from 'mobx-react';

@observer
export class App extends React.Component {
  render() {
    return <Routes />;
  }
}
