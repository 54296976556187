// tslint:disable
/**
 * tenant
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateOIDCAuthConnectorConfiguration
 */
export interface UpdateOIDCAuthConnectorConfiguration {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOIDCAuthConnectorConfiguration
     */
    enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOIDCAuthConnectorConfiguration
     */
    enabledForSandbox?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateOIDCAuthConnectorConfiguration
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOIDCAuthConnectorConfiguration
     */
    clientSecret: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOIDCAuthConnectorConfiguration
     */
    endpoint: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateOIDCAuthConnectorConfiguration
     */
    audiences?: Array<string>;
}

export function UpdateOIDCAuthConnectorConfigurationFromJSON(json: any): UpdateOIDCAuthConnectorConfiguration {
    return {
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'enabledForSandbox': !exists(json, 'enabled_for_sandbox') ? undefined : json['enabled_for_sandbox'],
        'clientId': json['client_id'],
        'clientSecret': json['client_secret'],
        'endpoint': json['endpoint'],
        'audiences': !exists(json, 'audiences') ? undefined : json['audiences'],
    };
}

export function UpdateOIDCAuthConnectorConfigurationToJSON(value?: UpdateOIDCAuthConnectorConfiguration): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'enabled': value.enabled,
        'enabled_for_sandbox': value.enabledForSandbox,
        'client_id': value.clientId,
        'client_secret': value.clientSecret,
        'endpoint': value.endpoint,
        'audiences': value.audiences,
    };
}


