// tslint:disable
/**
 * packetgrid-server-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DispatchReport,
    DispatchReportFromJSON,
    DispatchReportToJSON,
    SendNotification,
    SendNotificationFromJSON,
    SendNotificationToJSON,
} from '../models';

export interface SendRequest {
    command: SendNotification;
    idempotencyKey?: string;
}

/**
 * no description
 */
export class NotificationsApi extends runtime.BaseAPI {

    /**
     */
    async sendRaw(requestParameters: SendRequest): Promise<runtime.ApiResponse<DispatchReport>> {
        if (requestParameters.command === null || requestParameters.command === undefined) {
            throw new runtime.RequiredError('command','Required parameter requestParameters.command was null or undefined when calling send.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.idempotencyKey !== undefined && requestParameters.idempotencyKey !== null) {
            headerParameters['Idempotency-Key'] = String(requestParameters.idempotencyKey);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/notifications/send/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendNotificationToJSON(requestParameters.command),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DispatchReportFromJSON(jsonValue));
    }

    /**
     */
    async send(requestParameters: SendRequest): Promise<DispatchReport> {
        const response = await this.sendRaw(requestParameters);
        return await response.value();
    }

}
