import React from 'react';
import { pageNotFound } from './PageNotFound.scss';

interface Props {

}

export function PageNotFound(props: Props) {
  return (
    <div className={pageNotFound}>
      PageNotFound
    </div>
  );
}
