import React from 'react';
import { metric, label, value } from './Metric.scss';
import { Card } from '@blueprintjs/core';
import classes from '../../utils/classes';
import { column, alignCenter, justifyCenter } from '../../common.scss';

interface Props {
  label: string;
  value: React.ReactNode;
  color?: string;
}

export function Metric(props: Props) {
  return (
    <Card className={classes(metric, column, alignCenter, justifyCenter)} elevation={1}>
      <div className={value} style={{ color: props.color }}>
        {props.value}
      </div>
      <div className={label}>
        {props.label}
      </div>
    </Card>
  );
}
