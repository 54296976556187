import React from 'react';
import { copiableValue, content } from './CopiableValue.scss';
import { Button, Tooltip, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classes from '../../utils/classes';
import { row } from '../../common.scss';
import { copyToClipboard } from '../../utils/clipboard';

interface Props {
  value: string;
  type?: string;
}

export function CopiableValue(props: Props) {
  return (
    <div className={classes(copiableValue, row)}>
      <span className={content}>
        {props.type === 'password' ? '•'.repeat(props.value.length)  : props.value}
      </span>
      <Tooltip content="Copy to clipboard">
        <Button
          icon={IconNames.CLIPBOARD}
          intent={Intent.SUCCESS}
          minimal={true}
          onClick={() => copyToClipboard(props.value)}
        />
      </Tooltip>
    </div>
  );
}
