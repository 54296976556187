import React from 'react';
import { navLink, mobileNav, mobileNavDrawer, logo } from './HomeNavigation.scss';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { sidebar, inner } from '../home-page/assets/sass/main.scss';
import { Drawer } from '../../../components/drawer/Drawer';
import { Link } from '../../../components/link/Link';
import { Logo } from '../../../components/logo/Logo';

interface Props {

}

export class HomeNavigation extends React.Component<Props> {

  state = {
    isMobileNavOpen: false,
  };

  links() {
    return [
      <Link key={0} to="/#">Welcome</Link>,
      <Link key={1} to="/#how-it-works">How it works</Link>,
      <Link key={2} to="/#features">Features</Link>,
      <Link key={3} to="/#contact">Get Packet Grid</Link>,
      <Link key={4} to="/dashboard/login/">Login</Link>,
    ];
  }

  desktop() {
    return (
      <section id={sidebar}>
        <div className={inner}>
          <nav>
            <ul>
              {this.links().map((link, i) =>
                <li key={i} className={navLink}>{link}</li>,
              )}
            </ul>
          </nav>
        </div>
      </section>
    );
  }

  mobile() {
    return (
      <div className={mobileNav}>
        <Icon icon={IconNames.MENU} onClick={() => this.setState({ isMobileNavOpen: !this.state.isMobileNavOpen})} />
        <Logo className={logo} />
        <Icon icon={IconNames.PHONE} />
        <Drawer
          isOpen={this.state.isMobileNavOpen}
          onRequestClose={() => this.setState({ isMobileNavOpen: false })}
        >
          <div className={mobileNavDrawer}>
            {this.links()}
          </div>
        </Drawer>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.desktop()}
        {this.mobile()}
      </>
    );
  }
}
