import React from 'react';
import { appLayout, content } from './AppLayout.scss';
import { AppBar } from '../app-bar/AppBar';
import classes from '../../utils/classes';
import { column, flex1 } from '../../common.scss';

interface Props {
  children: React.ReactNode;
}

export function AppLayout(props: Props) {
  return (
    <div className={classes(appLayout, column)} style={{ height: '100vh' }} >
      <AppBar />
      <div className={classes(content, column, flex1)}>
        {props.children}
      </div>
    </div>
  );
}
