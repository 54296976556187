// tslint:disable
/**
 * tenant
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Tenant,
    TenantFromJSON,
    TenantToJSON,
    UpdateTenant,
    UpdateTenantFromJSON,
    UpdateTenantToJSON,
} from '../models';

export interface UpdateTenantRequest {
    tenant?: UpdateTenant;
}

/**
 * no description
 */
export class TenantApi extends runtime.BaseAPI {

    /**
     * retrieve your tenant infomation
     */
    async retrieveTenantRaw(): Promise<runtime.ApiResponse<Tenant>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantFromJSON(jsonValue));
    }

    /**
     * retrieve your tenant infomation
     */
    async retrieveTenant(): Promise<Tenant> {
        const response = await this.retrieveTenantRaw();
        return await response.value();
    }

    /**
     * update a Tenant
     */
    async updateTenantRaw(requestParameters: UpdateTenantRequest): Promise<runtime.ApiResponse<Tenant>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTenantToJSON(requestParameters.tenant),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantFromJSON(jsonValue));
    }

    /**
     * update a Tenant
     */
    async updateTenant(requestParameters: UpdateTenantRequest): Promise<Tenant> {
        const response = await this.updateTenantRaw(requestParameters);
        return await response.value();
    }

}
