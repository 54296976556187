import React from 'react';
import { observer } from 'mobx-react';
import { sharedTenantStore } from '../../stores/TenantStore';
import { Checkbox } from '@blueprintjs/core';
import { WhiteListPicker } from './WhiteListPicker';
import { confirmDangerousAction } from '../prompt/Prompt';
import { TenantService } from '../../services/TenantService';

interface Props {
  sandbox?: boolean;
}

@observer
export class WhiteListManagement extends React.Component<Props> {

  private tenantService = new TenantService();

  render() {
    const tenant = sharedTenantStore.tenant!;
    return (
      <div>
        {this.props.sandbox ? (
          <WhiteListPicker
            value={tenant.sandbox.whitelist}
            onChange={async (whitelist) => this.tenantService.updateSandboxWhitelist(whitelist)}
          />
        ) : (
          <>
            <Checkbox
              label="Enforce Whitelist"
              checked={tenant.whitelistEnabled}
              onChange={async (event) => {
                // event.persist() didn't work. Use local variable to get the checked.
                const isChecked = event.currentTarget.checked;
                if (await confirmDangerousAction('Are you sure?')) {
                  this.tenantService.enforceWhitelist(isChecked);
                }
              }}
            />
            {tenant.whitelistEnabled &&
              <WhiteListPicker
                value={tenant.whitelist}
                onChange={async (whitelist) => this.tenantService.updateWhitelist(whitelist)}
              />
            }
          </>
        )}
      </div>
    );
  }
}
