// tslint:disable
/**
 * tenant
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    TenantSandbox,
    TenantSandboxFromJSON,
    TenantSandboxToJSON,
} from './';

/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    primaryDomainUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    tenantId: string;
    /**
     * 
     * @type {TenantSandbox}
     * @memberof Tenant
     */
    sandbox: TenantSandbox;
    /**
     * 
     * @type {boolean}
     * @memberof Tenant
     */
    whitelistEnabled: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Tenant
     */
    whitelist: Array<string>;
}

export function TenantFromJSON(json: any): Tenant {
    return {
        'name': json['name'],
        'primaryDomainUrl': json['primary_domain_url'],
        'tenantId': json['tenant_id'],
        'sandbox': TenantSandboxFromJSON(json['sandbox']),
        'whitelistEnabled': json['whitelist_enabled'],
        'whitelist': json['whitelist'],
    };
}

export function TenantToJSON(value?: Tenant): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'primary_domain_url': value.primaryDomainUrl,
        'tenant_id': value.tenantId,
        'sandbox': TenantSandboxToJSON(value.sandbox),
        'whitelist_enabled': value.whitelistEnabled,
        'whitelist': value.whitelist,
    };
}


