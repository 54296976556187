// tslint:disable
/**
 * tenant
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TenantSandbox,
    TenantSandboxFromJSON,
    TenantSandboxToJSON,
    UpdateTenantSandbox,
    UpdateTenantSandboxFromJSON,
    UpdateTenantSandboxToJSON,
} from '../models';

export interface UpdateSandboxRequest {
    tenantSandbox?: UpdateTenantSandbox;
}

/**
 * no description
 */
export class TenantSandboxApi extends runtime.BaseAPI {

    /**
     * update a your tenant's API sandbox
     */
    async updateSandboxRaw(requestParameters: UpdateSandboxRequest): Promise<runtime.ApiResponse<TenantSandbox>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/sandbox/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTenantSandboxToJSON(requestParameters.tenantSandbox),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantSandboxFromJSON(jsonValue));
    }

    /**
     * update a your tenant's API sandbox
     */
    async updateSandbox(requestParameters: UpdateSandboxRequest): Promise<TenantSandbox> {
        const response = await this.updateSandboxRaw(requestParameters);
        return await response.value();
    }

}
