import React from 'react';
import classes from '../../utils/classes';
import { Classes, Button, Intent, Tag } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { sharedDispatchReportsStore } from '../../stores/DispatchReportsStore';
import { preloadOnce } from '../../components/preload-once';
import { display } from '../../components/display/display/Display';
import { Notification } from '../../../sdks/server-sdk';
import format from 'date-fns/format';
import { DispatchDetails } from './DispatchDetails';

interface Props {

}

@preloadOnce(async () => await sharedDispatchReportsStore.loadReports())
@observer
export class History extends React.Component<Props> {

  descriptionPreview(notification: Notification) {
    const chars = 40;
    return `${notification.detail.slice(0, chars)}${notification.detail.length > chars ? '...' : ''}`;
  }

  render() {
    const reports = sharedDispatchReportsStore.all();
    return (
      <div>
        <h1>Notification History (last 50)</h1>
        <table className={classes(Classes.HTML_TABLE, Classes.HTML_TABLE_STRIPED)}>
          <thead>
            <tr>
              <th>Time</th>
              <th>Title</th>
              <th>Detail</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report) =>
              <tr key={report.id}>
                <td>{format(report.startedAt, 'h:mmA D MMM YYYY')}</td>
                <td>{report.notification.title}</td>
                <td>
                  <pre style={{ margin: 0 }}>
                    {this.descriptionPreview(report.notification)}
                  </pre>
                </td>
                <td>
                  {report.transportReports.every((transport) => transport.successful) ? (
                    <Tag intent={Intent.SUCCESS} children="Delivered" minimal />
                  ) : (
                    <Tag intent={Intent.WARNING} children="Failures Detected" minimal />
                  )}
                </td>
                <td>
                  <Button
                    intent={Intent.NONE}
                    text="view"
                    onClick={async () => display(<DispatchDetails report={report} />)}
                  />
                </td>
              </tr>,
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
