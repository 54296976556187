// tslint:disable
/**
 * packetgrid-internal-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface CreateApiKey
 */
export interface CreateApiKey {
    /**
     * 
     * @type {string}
     * @memberof CreateApiKey
     */
    comment: string;
}

export function CreateApiKeyFromJSON(json: any): CreateApiKey {
    return {
        'comment': json['comment'],
    };
}

export function CreateApiKeyToJSON(value?: CreateApiKey): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'comment': value.comment,
    };
}


