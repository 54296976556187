// tslint:disable
/**
 * packetgrid-internal-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface TransportReport
 */
export interface TransportReport {
    /**
     * A string that identitifies the transport type
     * @type {string}
     * @memberof TransportReport
     */
    backendId: string;
    /**
     * This will be true if the transport delievered to all recipients successfully
     * @type {boolean}
     * @memberof TransportReport
     */
    successful: boolean;
    /**
     * 
     * @type {Array<object>}
     * @memberof TransportReport
     */
    failures: Array<object>;
}

export function TransportReportFromJSON(json: any): TransportReport {
    return {
        'backendId': json['backend_id'],
        'successful': json['successful'],
        'failures': json['failures'],
    };
}

export function TransportReportToJSON(value?: TransportReport): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'backend_id': value.backendId,
        'successful': value.successful,
        'failures': value.failures,
    };
}


