import { sharedAuthStore } from '../stores/AuthStore';
import { publicApiConfig } from '../api-config';
import { autoToastErrors } from '../decorators/auto-toast-errors';
import { AuthApi } from '../../sdks/tenants-sdk';

export class AuthService {

  private api = new AuthApi(publicApiConfig);

  @autoToastErrors()
  async login(username: string, password: string) {
    const token = await this.api.login({ credentials: { username, password } });
    sharedAuthStore.token = token.token;
    sharedAuthStore.username = username;
  }
}
