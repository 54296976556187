import React from 'react';
import { TagInput, Intent } from '@blueprintjs/core';
import { usernameSelector } from './WhiteListPicker.scss';

interface Props {
  value: string[];
  onChange: (value: string[]) => void;
}

export class WhiteListPicker extends React.Component<Props> {
  render() {
    return (
      <div>
        <TagInput
          className={usernameSelector}
          values={this.props.value}
          onAdd={(usernames) => this.props.onChange([...this.props.value, ...usernames])}
          onRemove={(_, index) => {
            const usernames = this.props.value.slice();
            usernames.splice(index, 1);
            this.props.onChange(usernames);
          }}
          inputProps={{ placeholder: 'Enter a username...', style: { width: '100%' } }}
          tagProps={{ large: true, minimal: true, intent: Intent.SUCCESS }}
        />
      </div>
    );
  }
}
