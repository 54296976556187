import { observable, action, computed } from 'mobx';

const TOKEN_KEY = 'AuthStore.token';
const USERNAME_KEY = 'AuthStore.username';
export class AuthStore {
  // note: mobx-state-decorators didn't set up the local storage when the app is deployed
  // under the docker container
  // @persist({ key: 'AuthStore.token' })
  // @observable
  // token?: string

  // @persist({ key: 'AuthStore.token' })
  // @observable
  // username?: string

  // tslint:disable variable-name
  @observable
  private _token = localStorage.getItem(TOKEN_KEY) || undefined;

  @observable
  private _username = localStorage.getItem(USERNAME_KEY) || undefined;

  isAuthenticated() {
    return this._token !== undefined;
  }

  @computed
  get token() {
    return this._token;
  }

  set token(token: string | undefined) {
    token
      ? localStorage.setItem(TOKEN_KEY, token)
      : localStorage.removeItem(TOKEN_KEY);
    this._token = token;
  }

  @computed
  get username() {
    return this._username;
  }

  set username(username: string | undefined) {
    username
      ? localStorage.setItem(USERNAME_KEY, username)
      : localStorage.removeItem(USERNAME_KEY);
    this._username = username;
  }

  @action
  logout() {
    this.token = undefined;
    this.username = undefined;
  }
}

export const sharedAuthStore = new AuthStore();
