// tslint:disable
/**
 * packetgrid-internal-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ApiUsageTransportUsage
 */
export interface ApiUsageTransportUsage {
    /**
     * 
     * @type {string}
     * @memberof ApiUsageTransportUsage
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ApiUsageTransportUsage
     */
    value: number;
}

export function ApiUsageTransportUsageFromJSON(json: any): ApiUsageTransportUsage {
    return {
        'name': json['name'],
        'value': json['value'],
    };
}

export function ApiUsageTransportUsageToJSON(value?: ApiUsageTransportUsage): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'value': value.value,
    };
}


