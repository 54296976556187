import { preload, AsyncPropsTransformer } from './preload';
import { memoize } from './util/memoize';

export function preloadOnce(
  callback: AsyncPropsTransformer,
  errorMessage: React.ReactNode = undefined,
  placeholder: React.ReactNode = undefined,
) {
  return preload(memoize(callback), errorMessage, placeholder);
}
