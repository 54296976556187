// tslint:disable
/**
 * tenant
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateTenantSandbox
 */
export interface UpdateTenantSandbox {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTenantSandbox
     */
    enabled?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTenantSandbox
     */
    whitelist?: Array<string>;
}

export function UpdateTenantSandboxFromJSON(json: any): UpdateTenantSandbox {
    return {
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'whitelist': !exists(json, 'whitelist') ? undefined : json['whitelist'],
    };
}

export function UpdateTenantSandboxToJSON(value?: UpdateTenantSandbox): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'enabled': value.enabled,
        'whitelist': value.whitelist,
    };
}


