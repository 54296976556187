// tslint:disable
/**
 * packetgrid-internal-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    detail: string;
    /**
     * 
     * @type {object}
     * @memberof Notification
     */
    payload: object;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    updatedAt: string;
}

export function NotificationFromJSON(json: any): Notification {
    return {
        'id': json['id'],
        'title': json['title'],
        'detail': json['detail'],
        'payload': json['payload'],
        'createdAt': json['created_at'],
        'updatedAt': json['updated_at'],
    };
}

export function NotificationToJSON(value?: Notification): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'title': value.title,
        'detail': value.detail,
        'payload': value.payload,
        'created_at': value.createdAt,
        'updated_at': value.updatedAt,
    };
}


