import React from 'react';
import { manageBrowserForms } from './ManageBrowserForms.scss';
import classes from '../../utils/classes';
import { column } from '../../common.scss';
import { Classes, Button, Intent, InputGroup, Callout, Tag, Tooltip, Position, TagInput } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { copyToClipboard } from '../../utils/clipboard';
import { codeBlock } from 'common-tags';
import { UserSelect } from '../user-select/UserSelect';
import { observer } from 'mobx-react';
import { sharedFormsStore } from '../../stores/FormsStore';
import { sharedTenantStore } from '../../stores/TenantStore';
import { FormsService } from '../../services/FormsService';
import { promptUser } from '../prompt/Prompt';
import { observable } from 'mobx';
import { toastServiceError } from '../../utils/toast-service-error';
import { preloadOnce } from '../preload-once';
import { Form } from '../form/Form';
import { AsyncActionButton } from '../async-action-button/AsyncActionButton';
import { Col } from '../flexbox/Col';
import { CreateBrowserForm, Recipient } from '../../../sdks/internal-sdk';

interface Props {

}

function browserFormCode(tenantUrl: string, slug: string) {
  return codeBlock`
    <form method="post" action="${tenantUrl}/api/forms/${slug}/submit/">
      <!-- your form inputs -->
      <!-- remember to include the "name" attribute on your inputs -->
      <button type="submit">submit</button>
    </form>
  `;
}

async function loadForms() {
  const service = new FormsService();
  await service.loadForms();
}

function emptyForm(): CreateBrowserForm {
  return {
    name: '',
    origins: [],
    recipients: [],
  };
}

@preloadOnce(loadForms)
@observer
export class ManageBrowserForms extends React.Component<Props> {

  private formsService = new FormsService();

  @observable
  private newForm = emptyForm();

  private onAddBrowserForm = async () => {
    await this.formsService.createForm(this.newForm);
    this.newForm = emptyForm();
  }

  private recipientTooltip(recipient: Recipient) {
    return (
      <table>
        <tbody>
          <tr>
            <td>Email</td>
            <td style={{ textAlign: 'right' }}>{recipient.email || '-'}</td>
          </tr>
          <tr>
            <td>Phone</td>
            <td style={{ textAlign: 'right' }}>{recipient.phone || '-'}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  // tslint:disable-next-line
  render() {
    return (
      <div className={manageBrowserForms}>
        <Callout title="" intent={Intent.PRIMARY}>
          <p>
            You can setup a browser form to send a notification on submission - Packet Grid supports this to
            simplify basic forms on your website such as a contact form.
          </p>
          <p>
            Simply give the form a name, enter the domain name of the website this form will be used on and
            then add a list of recipients which should be notified.
          </p>
          <p>
            There's a handy code icon <Button icon={IconNames.CODE} intent={Intent.NONE} /> that will
            automatically put the HTML code for your form into your clipboard.
          </p>
        </Callout>
        <Form className={classes(column)}>
          <table className={classes(Classes.HTML_TABLE, Classes.HTML_TABLE_STRIPED)}>
            <thead>
              <tr>
                <th>Created On</th>
                <th>Name</th>
                <th>Origins</th>
                <th>Recipients</th>
                <th>Code</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {sharedFormsStore.all().map((browserForm) =>
                <tr key={browserForm.name}>
                  <td>{browserForm.createdAt}</td>
                  <td>{browserForm.name}</td>
                  <td>
                    <Col>
                      {browserForm.origins.map((origin, i) => <Tag key={i}>{origin}</Tag>)}
                    </Col>
                  </td>
                  <td>
                    {browserForm.recipients.map((recipient, i) =>
                      <Tooltip key={i} position={Position.TOP} content={this.recipientTooltip(recipient)}>
                        <Tag>
                          {recipient.username}
                        </Tag>
                      </Tooltip>,
                    )}
                  </td>
                  <td>
                    <Button
                      icon={IconNames.CODE}
                      intent={Intent.NONE}
                      onClick={() => {
                        copyToClipboard(browserFormCode(sharedTenantStore.tenant!.primaryDomainUrl, browserForm.slug));
                      }}
                    />
                  </td>
                  <td>
                    <Button
                      icon={IconNames.CROSS}
                      intent={Intent.DANGER}
                      onClick={async () => {
                        if (await promptUser({ message: 'are you sure?' })) {
                          try {
                            await this.formsService.deleteForm(browserForm.slug);
                          } catch (err) {
                            await toastServiceError(err);
                          }
                        }
                      }}
                    />
                  </td>
                </tr>,
              )}
              <tr>
                <td>
                  <strong>
                    Add
                  </strong>
                </td>
                <td>
                  <InputGroup
                    autoComplete="off"
                    placeholder="name..."
                    value={this.newForm.name}
                    onChange={(event: any) => this.newForm.name = event.target.value}
                    required
                  />
                </td>
                <td>
                  <TagInput
                    inputProps={{
                      onKeyPress: (event) => {
                        if (event.key === 'Enter') {
                          // when the user presses enter in the tag input
                          // we don't want to submit the form, they're just
                          // adding a value in the tag input.
                          event.preventDefault();
                        }
                      },
                    }}
                    values={this.newForm.origins}
                    leftIcon={IconNames.GLOBE}
                    onChange={(values) => {
                      this.newForm.origins = values as string[];
                    }}
                  />
                </td>
                <td>
                  <UserSelect
                    value={this.newForm.recipients}
                    onChange={(recipients) => { this.newForm.recipients = recipients; }}
                  />
                </td>
                <td />
                <td>
                  <AsyncActionButton
                    onClick={this.onAddBrowserForm}
                    icon={IconNames.PLUS}
                    intent={Intent.SUCCESS}
                    type="submit"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Form>
      </div>
    );
  }
}
