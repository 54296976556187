import { sharedFormsStore } from '../stores/FormsStore';
import { internalNotificationsApiConfig } from '../api-config';
import { autoToastErrors } from '../decorators/auto-toast-errors';
import { BrowserFormsApi, CreateBrowserForm } from '../../sdks/internal-sdk';

export class FormsService {
  private forms = new BrowserFormsApi(internalNotificationsApiConfig);

  @autoToastErrors()
  async loadForms() {
    const forms = await this.forms.listBrowserForms({});
    sharedFormsStore.add(...forms);
  }

  @autoToastErrors()
  async createForm(browserForm: CreateBrowserForm) {
    const form = await this.forms.createBrowserForm({ browserForm });
    sharedFormsStore.add(form);
  }

  @autoToastErrors()
  async deleteForm(slug: string) {
    await this.forms.deleteBrowserForm({ slug });
    sharedFormsStore.deleteById(slug);
  }
}
