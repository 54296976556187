import { Configuration as InternalApiConfiguration } from '../sdks/internal-sdk';
import { Configuration as PublicApiConfiguration } from '../sdks/tenants-sdk';
import { Configuration as TenantApiConfiguration } from '../sdks/server-sdk';
import { PACKETGRID_API_URL, PACKETGRID_SANDBOX_URL } from './config';
import { InvalidTokenMiddleware, TenantHeaderMiddleware } from './api-middleware';
import { apiKey } from './api-config-helpers';
import urljoin from 'url-join';

const publicApiMiddleware = [
  new InvalidTokenMiddleware(),
];

const tenantApiMiddleware = [
  new InvalidTokenMiddleware(),
  new TenantHeaderMiddleware(),
];

export const publicApiConfig = new PublicApiConfiguration({
  basePath: urljoin(PACKETGRID_API_URL, 'api/tenant'),
  apiKey,
  middleware: publicApiMiddleware,
});

export const notificationsApiConfig = new TenantApiConfiguration({
  basePath: urljoin(PACKETGRID_API_URL, 'api'),
  apiKey,
  middleware: tenantApiMiddleware,
});

export const sandboxNotificationsApiConfig = new TenantApiConfiguration({
  basePath: urljoin(PACKETGRID_SANDBOX_URL, 'api'),
  apiKey,
  middleware: tenantApiMiddleware,
});


export const internalNotificationsApiConfig = new InternalApiConfiguration({
  basePath: urljoin(PACKETGRID_API_URL, 'api'),
  apiKey,
  middleware: tenantApiMiddleware,
});

export const internalSandboxNotificationsApiConfig = new InternalApiConfiguration({
  basePath: urljoin(PACKETGRID_SANDBOX_URL, 'api'),
  apiKey,
  middleware: tenantApiMiddleware,
});
