import React from 'react';
import { collapsibleSection } from './CollapsibleSection.scss';
import { Collapse, Button } from '@blueprintjs/core';
import classes from '../../utils/classes';
import { row, justifyBetween, alignCenter } from '../../common.scss';

interface Props {
  label: string;
  description: string;
}

export class CollapsibleSection extends React.Component<Props> {

  state = {
    isOpen: false,
  };

  render() {
    return (
      <div className={collapsibleSection}>
        <div className={classes(row, justifyBetween, alignCenter)}>
          <div>
            <h2>{this.props.label}</h2>
            <p>{this.props.description}</p>
          </div>
          <Button
            text={this.state.isOpen ? 'hide' : 'show'}
            onClick={() => this.setState({ isOpen: !this.state.isOpen })}
          />
        </div>
        <Collapse isOpen={this.state.isOpen}>
          {this.props.children}
        </Collapse>
      </div>
    );
  }
}
