import React from 'react';
import { desktopNav, mobileNav } from './AppBar.scss';
import { Navbar, Alignment, Button } from '@blueprintjs/core';
import { sharedAuthStore } from '../../stores/AuthStore';
import { history } from '../../history';
import { Link } from '../link/Link';
import { observer } from 'mobx-react';
import { sharedTenantStore } from '../../stores/TenantStore';
import { capitalize } from '../util/text-utils';
import { Row } from '../flexbox/Row';
import { Gutter } from '../flexbox/FlexProps';
import { HamburgerMenu } from '../hamburger-menu/HamburgerMenu';

@observer
export class AppBar extends React.Component {

  links() {
    const isAuthenticated = sharedAuthStore.isAuthenticated();
    return (
      <>
        <div>
          {isAuthenticated &&
            <Button role="link" text="Dashboard" onClick={() => history.push('/dashboard/')} />
          }
        </div>
        {/* {isAuthenticated &&
          <div>
            <Button role="link" text="Docs" onClick={() => history.push('/dashboard/docs/')} />
          </div>
        } */}
        {isAuthenticated ? (
          <div>
            <Button role="link" text="Logout" onClick={() => sharedAuthStore.logout()} />
          </div>
        ) : (
          <div>
            <Button role="link" text="Login" onClick={() => history.push('/dashboard/login/')} />
          </div>
        )}
      </>
    );
  }
  render() {
    const tenant = sharedTenantStore.tenant;
    const isAuthenticated = sharedAuthStore.isAuthenticated();
    return (
      <Navbar>
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>
            <Link to={isAuthenticated ? '/dashboard/' : '/'}>
              Packet Grid
              {tenant !== undefined &&
                <span> - {capitalize(tenant.name)}</span>
              }
            </Link>
          </Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT} className={desktopNav}>
          <Row gutter={Gutter.small}>
            {this.links()}
          </Row>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT} className={mobileNav}>
          <HamburgerMenu>
            <Link exact to="/dashboard/">Dashboard</Link>
            <Link exact to="/dashboard/sandbox/">Sandbox</Link>
            <Link exact to="/dashboard/notifications/dispatch/">Dispatch</Link>
            <Link exact to="/dashboard/settings/">Settings</Link>
          </HamburgerMenu>
        </Navbar.Group>
      </Navbar>
    );
  }
}
