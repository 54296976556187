import React from 'react';
import { sandbox } from './Sandbox.scss';
import { Callout, Intent, Tag } from '@blueprintjs/core';
import { sharedTenantStore } from '../../stores/TenantStore';
import { Gutter } from '../../components/flexbox/FlexProps';
import { observer } from 'mobx-react';
import { ManageSandbox } from './manage-sandbox/ManageSandbox';
import { IconNames } from '@blueprintjs/icons';
import { Row } from '../../components/flexbox/Row';
import { ToggleApiSandboxButton } from '../../components/toggle-api-sandbox-button/ToggleApiSandboxButton';

interface Props {

}

@observer
export class Sandbox extends React.Component<Props> {
  render() {
    return (
      <div className={sandbox}>
        <Row alignItems="center" gutter={Gutter.small}>
          <h1>Packet Grid API Sandbox</h1>
          <Tag minimal rightIcon={IconNames.SMALL_TICK} intent={Intent.SUCCESS}>
            Enabled
          </Tag>
        </Row>
        <Callout intent={Intent.PRIMARY}>
          <p>
            The API sandbox allows you to start developing your apps with Packet Grid without any fees or
            accidental notifications 👍
            </p>
          <p>
            The API sandbox is isolated from:
            </p>
          <ul>
            <li>Real notifications being sent</li>
            <li>Your production users and API keys</li>
          </ul>
        </Callout>
        {sharedTenantStore.tenant!.sandbox.enabled &&
          <ManageSandbox />
        }
        <hr />
        <ToggleApiSandboxButton />
      </div>
    );
  }
}
