import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { history } from './history';
import { Login } from './pages/login/Login';
import { AuthenticatedRoute } from './components/authenticated-route/AuthenticatedRoute';
import { Home } from './pages/home/Home';
import { AppLayout } from './components/app-layout/AppLayout';
import { Dashboard } from './pages/dashboard/Dashboard';
import { PageNotFound } from './components/page-not-found/PageNotFound';

class Router extends BrowserRouter {
  history = history;
}

export function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/dashboard/">
          <AppLayout>
            <Switch>
              <Route exact path="/dashboard/login/" component={Login} />
              {/* <AuthenticatedRoute path="/dashboard/docs/" component={Documentation} /> */}
              <AuthenticatedRoute path="/dashboard/" component={Dashboard} />
            </Switch>
          </AppLayout>
        </Route>
        <Route exact path="*" component={PageNotFound} />
      </Switch>
    </Router>
  );
}
