import { observable } from 'mobx';
import { internalNotificationsApiConfig } from '../api-config';
import { autoToastErrors } from '../decorators/auto-toast-errors';
import { ApiUsage, ApiUsageApi } from '../../sdks/internal-sdk';
import moment from 'moment';

export class ApiUsageStore {
  @observable
  overview?: ApiUsage;

  private api = new ApiUsageApi(internalNotificationsApiConfig);

  @autoToastErrors()
  async loadOverview() {
    // default to load the last 90 days
    this.overview = await this.api.retrieveOverview({
      startDate: moment().subtract(90, 'd').format(),
      endDate: moment().format()});
  }
}

export const sharedApiUsageStore = new ApiUsageStore();
