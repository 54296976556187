// tslint:disable
/**
 * tenant
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateOIDCAuthConnectorConfiguration,
    CreateOIDCAuthConnectorConfigurationFromJSON,
    CreateOIDCAuthConnectorConfigurationToJSON,
    OIDCAuthConnectorConfiguration,
    OIDCAuthConnectorConfigurationFromJSON,
    OIDCAuthConnectorConfigurationToJSON,
    UpdateOIDCAuthConnectorConfiguration,
    UpdateOIDCAuthConnectorConfigurationFromJSON,
    UpdateOIDCAuthConnectorConfigurationToJSON,
} from '../models';

export interface CreateOIDCAuthConnectorConfigurationRequest {
    oIDCAuthConnectorConfiguration?: CreateOIDCAuthConnectorConfiguration;
}

export interface DeleteOIDCAuthConnectorConfigurationRequest {
    id: number;
}

export interface RetrieveOIDCAuthConnectorConfigurationRequest {
    id: number;
}

export interface UpdateOIDCAuthConnectorConfigurationRequest {
    id: number;
    oIDCAuthConnectorConfiguration?: UpdateOIDCAuthConnectorConfiguration;
}

/**
 * no description
 */
export class OIDCAuthConnectorConfigurationsApi extends runtime.BaseAPI {

    /**
     * create a new OIDCAuthConnectorConfiguration
     */
    async createOIDCAuthConnectorConfigurationRaw(requestParameters: CreateOIDCAuthConnectorConfigurationRequest): Promise<runtime.ApiResponse<OIDCAuthConnectorConfiguration>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/openid-connect/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOIDCAuthConnectorConfigurationToJSON(requestParameters.oIDCAuthConnectorConfiguration),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OIDCAuthConnectorConfigurationFromJSON(jsonValue));
    }

    /**
     * create a new OIDCAuthConnectorConfiguration
     */
    async createOIDCAuthConnectorConfiguration(requestParameters: CreateOIDCAuthConnectorConfigurationRequest): Promise<OIDCAuthConnectorConfiguration> {
        const response = await this.createOIDCAuthConnectorConfigurationRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete a single OIDCAuthConnectorConfiguration by id
     */
    async deleteOIDCAuthConnectorConfigurationRaw(requestParameters: DeleteOIDCAuthConnectorConfigurationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOIDCAuthConnectorConfiguration.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/openid-connect/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete a single OIDCAuthConnectorConfiguration by id
     */
    async deleteOIDCAuthConnectorConfiguration(requestParameters: DeleteOIDCAuthConnectorConfigurationRequest): Promise<void> {
        await this.deleteOIDCAuthConnectorConfigurationRaw(requestParameters);
    }

    /**
     * list OIDCAuthConnectorConfigurations
     */
    async listOIDCAuthConnectorConfigurationsRaw(): Promise<runtime.ApiResponse<Array<OIDCAuthConnectorConfiguration>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/openid-connect/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OIDCAuthConnectorConfigurationFromJSON));
    }

    /**
     * list OIDCAuthConnectorConfigurations
     */
    async listOIDCAuthConnectorConfigurations(): Promise<Array<OIDCAuthConnectorConfiguration>> {
        const response = await this.listOIDCAuthConnectorConfigurationsRaw();
        return await response.value();
    }

    /**
     * retrieve your tenants openid-connect authentication connector configuration
     */
    async retrieveOIDCAuthConnectorConfigurationRaw(requestParameters: RetrieveOIDCAuthConnectorConfigurationRequest): Promise<runtime.ApiResponse<OIDCAuthConnectorConfiguration>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling retrieveOIDCAuthConnectorConfiguration.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/openid-connect/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OIDCAuthConnectorConfigurationFromJSON(jsonValue));
    }

    /**
     * retrieve your tenants openid-connect authentication connector configuration
     */
    async retrieveOIDCAuthConnectorConfiguration(requestParameters: RetrieveOIDCAuthConnectorConfigurationRequest): Promise<OIDCAuthConnectorConfiguration> {
        const response = await this.retrieveOIDCAuthConnectorConfigurationRaw(requestParameters);
        return await response.value();
    }

    /**
     * update your tenants openid-connect authentication connector configuration
     */
    async updateOIDCAuthConnectorConfigurationRaw(requestParameters: UpdateOIDCAuthConnectorConfigurationRequest): Promise<runtime.ApiResponse<OIDCAuthConnectorConfiguration>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateOIDCAuthConnectorConfiguration.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/openid-connect/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOIDCAuthConnectorConfigurationToJSON(requestParameters.oIDCAuthConnectorConfiguration),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OIDCAuthConnectorConfigurationFromJSON(jsonValue));
    }

    /**
     * update your tenants openid-connect authentication connector configuration
     */
    async updateOIDCAuthConnectorConfiguration(requestParameters: UpdateOIDCAuthConnectorConfigurationRequest): Promise<OIDCAuthConnectorConfiguration> {
        const response = await this.updateOIDCAuthConnectorConfigurationRaw(requestParameters);
        return await response.value();
    }

}
