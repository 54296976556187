// tslint:disable
/**
 * packetgrid-internal-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Notification,
    NotificationFromJSON,
    NotificationToJSON,
    TransportReport,
    TransportReportFromJSON,
    TransportReportToJSON,
} from './';

/**
 * 
 * @export
 * @interface DispatchReport
 */
export interface DispatchReport {
    /**
     * 
     * @type {string}
     * @memberof DispatchReport
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof DispatchReport
     */
    startedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DispatchReport
     */
    finishedAt: Date;
    /**
     * 
     * @type {Array<TransportReport>}
     * @memberof DispatchReport
     */
    transportReports: Array<TransportReport>;
    /**
     * 
     * @type {Notification}
     * @memberof DispatchReport
     */
    notification: Notification;
}

export function DispatchReportFromJSON(json: any): DispatchReport {
    return {
        'id': json['id'],
        'startedAt': new Date(json['started_at']),
        'finishedAt': new Date(json['finished_at']),
        'transportReports': (json['transport_reports'] as Array<any>).map(TransportReportFromJSON),
        'notification': NotificationFromJSON(json['notification']),
    };
}

export function DispatchReportToJSON(value?: DispatchReport): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'started_at': value.startedAt.toISOString(),
        'finished_at': value.finishedAt.toISOString(),
        'transport_reports': (value.transportReports as Array<any>).map(TransportReportToJSON),
        'notification': NotificationToJSON(value.notification),
    };
}


