import React from 'react';
import { manageSandbox } from './ManageSandbox.scss';
import { sharedTenantStore } from '../../../stores/TenantStore';
import { Callout, Tooltip, Icon, Intent } from '@blueprintjs/core';
import classes from '../../../utils/classes';
import { row, alignCenter, spaceChildrenHorizontal } from '../../../common.scss';
import { CopiableValue } from '../../../components/copiable-value/CopiableValue';
import { ApiKeyTable } from '../../../components/api-key-table/ApiKeyTable';
import { preloadOnce } from '../../../components/preload-once';
import { sharedSandboxApiKeyStore } from '../../../stores/ApiKeyStore';
import { observer } from 'mobx-react';
import { Col } from '../../../components/flexbox/Col';
import { Gutter } from '../../../components/flexbox/FlexProps';
import { WhiteListManagement } from '../../../components/white-list-management/WhiteListManagement';
import { IconNames } from '@blueprintjs/icons';
import { Row } from '../../../components/flexbox/Row';

interface Props {

}

@preloadOnce(async () => await sharedSandboxApiKeyStore.loadKeys())
@observer
export class ManageSandbox extends React.Component<Props> {
  render() {
    const sandbox = sharedTenantStore.tenant!.sandbox;
    return (
      <Col className={manageSandbox} gutter={Gutter.medium}>
        <div>
          <h2>Api Endpoint</h2>
          <Callout>
            <div className={classes(row, alignCenter, spaceChildrenHorizontal)}>
              <strong>Api Endpoint</strong>
              <CopiableValue value={sandbox.primaryDomainUrl} />
            </div>
          </Callout>
        </div>
        <div>
          <h2>
            Api Keys
          </h2>
          <ApiKeyTable
            keys={sharedSandboxApiKeyStore.all()}
            onKeyCreate={async (key) => sharedSandboxApiKeyStore.createKey(key)}
            onKeyDelete={async (key) => sharedSandboxApiKeyStore.deleteKey(key.id)}
          />
        </div>
        <div>
          <Row gutter={Gutter.small} alignItems="center">
            <h2>
              White List
            </h2>
            <Tooltip
              content={
                <span>
                  Enter a list of usernames in the input box below.
                  <br />
                  These users will receive notifications from Sandbox API calls
                </span>
              }
            >
              <Icon icon={IconNames.HELP} iconSize={20} intent={Intent.PRIMARY} />
            </Tooltip>
          </Row>
          <WhiteListManagement sandbox />
        </div>
      </Col>
    );
  }
}
