// tslint:disable
/**
 * packetgrid-server-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    detail: string;
}

export function ModelErrorFromJSON(json: any): ModelError {
    return {
        'detail': json['detail'],
    };
}

export function ModelErrorToJSON(value?: ModelError): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'detail': value.detail,
    };
}


