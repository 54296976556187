// tslint:disable
/**
 * tenant
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TenantUser,
    TenantUserFromJSON,
    TenantUserToJSON,
} from '../models';

/**
 * no description
 */
export class TenantUsersApi extends runtime.BaseAPI {

    /**
     * retrieve your tenant infomation
     */
    async meRaw(): Promise<runtime.ApiResponse<TenantUser>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/users/me/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantUserFromJSON(jsonValue));
    }

    /**
     * retrieve your tenant infomation
     */
    async me(): Promise<TenantUser> {
        const response = await this.meRaw();
        return await response.value();
    }

}
