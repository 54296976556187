import React from 'react';
import { apiKeyManagement } from './ApiKeyManagement.scss';
import classes from '../../utils/classes';
import { Intent, Callout } from '@blueprintjs/core';
import { spaceChildrenVertical } from '../../common.scss';
import { observer } from 'mobx-react';
import { sharedApiKeyStore } from '../../stores/ApiKeyStore';
import { preloadOnce } from '../preload-once';
import { sharedTenantStore } from '../../stores/TenantStore';
import { Term } from '../term/Term';
import { ApiKeyTable } from '../api-key-table/ApiKeyTable';

interface Props {

}

@preloadOnce(async () => await sharedApiKeyStore.loadKeys())
@observer
export class ApiKeyManagement extends React.Component<Props> {
  render() {
    const { primaryDomainUrl, tenantId } = sharedTenantStore.tenant!;
    return (
      <div className={classes(apiKeyManagement, spaceChildrenVertical)}>
        <Callout title="How to connect" intent={Intent.PRIMARY}>
          <ol>
            <li>Create an API key using the table below</li>
            <li>Connect to Packet Grid: <a href={primaryDomainUrl}>{primaryDomainUrl}</a></li>
            <li>
              Include your Tenant Id in the Tenant-Id header:
              <br />
              <Term>Tenant-Id: "{tenantId}"</Term>
            </li>
            <li>
              Include your API key in the Authorization header:
              <br />
              <Term>Authorization: "Token {'<your-api-key>'}"</Term>
            </li>
          </ol>
        </Callout>
        <ApiKeyTable
          keys={sharedApiKeyStore.all()}
          onKeyCreate={async (key) => sharedApiKeyStore.createKey(key)}
          onKeyDelete={async (key) => sharedApiKeyStore.deleteKey(key.id)}
        />
      </div>
    );
  }
}
