export function mapBackendIdToDisplayName(backendId: string) {
  // Note: The backendId is determined by the notification core library
  // and it is stored on every record in the report model, which will require data migrations
  // and change the name in core library.
  // To make the change simple, intercept the id to the display name here
  switch (backendId) {
    case 'TwilioBackend':
      return 'SMS';
    case 'FCMPushBackend':
      return 'Push';
    case 'EmailBackend':
      return 'Email';
    default:
      return backendId;
  }
}
