// tslint:disable
/**
 * tenant
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateTenant
 */
export interface UpdateTenant {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTenant
     */
    whitelistEnabled?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTenant
     */
    whitelist?: Array<string>;
}

export function UpdateTenantFromJSON(json: any): UpdateTenant {
    return {
        'whitelistEnabled': !exists(json, 'whitelist_enabled') ? undefined : json['whitelist_enabled'],
        'whitelist': !exists(json, 'whitelist') ? undefined : json['whitelist'],
    };
}

export function UpdateTenantToJSON(value?: UpdateTenant): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'whitelist_enabled': value.whitelistEnabled,
        'whitelist': value.whitelist,
    };
}


