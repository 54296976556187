// tslint:disable
/**
 * packetgrid-internal-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Recipient
 */
export interface Recipient {
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Recipient
     */
    phone?: string;
}

export function RecipientFromJSON(json: any): Recipient {
    return {
        'username': json['username'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function RecipientToJSON(value?: Recipient): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'username': value.username,
        'email': value.email,
        'phone': value.phone,
    };
}


