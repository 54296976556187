import React from 'react';
import { RouteProps, Route } from 'react-router-dom';
import { sharedAuthStore } from '../../stores/AuthStore';
import { history } from '../../history';
import { autorun } from 'mobx';

interface Props extends RouteProps {

}

export class AuthenticatedRoute extends React.Component<Props> {

  private disposer?: Function;

  private redirectAnon = () => {
    if (!sharedAuthStore.isAuthenticated() && !history.location.pathname.startsWith('/dashboard/login')) {
      history.push('/dashboard/login/');
    }
  }

  componentDidMount() {
    this.disposer = autorun(this.redirectAnon);
  }

  componentWillUnmount() {
    if (this.disposer) {
      this.disposer();
    }
  }

  render() {
    return <Route {...this.props} />;
  }
}
