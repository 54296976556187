import React from 'react';

interface Props {
  children: string | string[];
}

export function Term(props: Props) {
  return (
    <span style={{ fontFamily: 'monospace', color: '#b74e91' }}>{props.children}</span>
  );
}
