import React from 'react';
import { tenantSettings } from './TenantSettings.scss';
import { CollapsibleSection } from '../../components/collapsible-section/CollapsibleSection';
import { ApiKeyManagement } from '../../components/api-key-management/ApiKeyManagement';
import { ManageBrowserForms } from '../../components/manage-browser-forms/ManageBrowserForms';
import { WhiteListManagement } from '../../components/white-list-management/WhiteListManagement';

interface Props {

}

export class TenantSettings extends React.Component<Props> {
  render() {
    return (
      <div className={tenantSettings}>
        <h1>Settings</h1>
        <hr />
        <CollapsibleSection
          label="Whitelist"
          description="Control a whitelist for your production API"
        >
          <WhiteListManagement />
        </CollapsibleSection>
        <hr />
        <CollapsibleSection
          label="API Keys"
          description="Manage your API access keys"
        >
          <ApiKeyManagement />
        </CollapsibleSection>
        <hr />
        <CollapsibleSection
          label="Browser Forms"
          description="Manage browser forms"
        >
          <ManageBrowserForms />
        </CollapsibleSection>
      </div>
    );
  }
}
