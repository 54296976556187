// tslint:disable
/**
 * packetgrid-server-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ClientToken,
    ClientTokenFromJSON,
    ClientTokenToJSON,
    CreateClientToken,
    CreateClientTokenFromJSON,
    CreateClientTokenToJSON,
} from '../models';

export interface CreateClientTokenRequest {
    body: CreateClientToken;
}

/**
 * no description
 */
export class ClientTokenApi extends runtime.BaseAPI {

    /**
     * create a client token for a mobile device. If a duplicated user_id is provided, the server could probably create a new token or return the existig token. 
     */
    async createClientTokenRaw(requestParameters: CreateClientTokenRequest): Promise<runtime.ApiResponse<ClientToken>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createClientToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Token authentication
        }

        const response = await this.request({
            path: `/client-tokens/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateClientTokenToJSON(requestParameters.body),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientTokenFromJSON(jsonValue));
    }

    /**
     * create a client token for a mobile device. If a duplicated user_id is provided, the server could probably create a new token or return the existig token. 
     */
    async createClientToken(requestParameters: CreateClientTokenRequest): Promise<ClientToken> {
        const response = await this.createClientTokenRaw(requestParameters);
        return await response.value();
    }

}
