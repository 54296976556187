import React from 'react';
import { loginPage, loginCard, logo, loginForm, background } from './Login.scss';
import { Intent, FormGroup, InputGroup } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { history } from '../../history';
import { AuthService } from '../../services/AuthService';
import { Logo } from '../../components/logo/Logo';
import { IconNames } from '@blueprintjs/icons';
import { Form } from '../../components/form/Form';
import { AsyncActionButton } from '../../components/async-action-button/AsyncActionButton';

@observer
export class Login extends React.Component {

  private auth = new AuthService();

  @observable
  private username = '';

  @observable
  private password = '';

  private login = async () => {
    await this.auth.login(this.username, this.password);
    history.push('/dashboard/');
  }

  render() {
    return (
      <div className={loginPage}>
        <div className={loginCard}>
          <Logo className={logo} />
          <Form className={loginForm}>
            <FormGroup>
              <InputGroup
                id="username"
                name="username"
                placeholder="username..."
                leftIcon={IconNames.USER}
                value={this.username}
                onChange={(event: any) => this.username = event.currentTarget.value}
                required
              />
            </FormGroup>
            <FormGroup>
              <InputGroup
                id="password"
                name="password"
                placeholder="password..."
                type="password"
                leftIcon={IconNames.KEY}
                value={this.password}
                onChange={(event: any) => this.password = event.currentTarget.value}
                required
              />
            </FormGroup>
            <FormGroup>
              <AsyncActionButton
                onClick={this.login}
                type="submit"
                text="Login"
                intent={Intent.SUCCESS}
              />
            </FormGroup>
          </Form>
        </div>
        <svg
          className={background}
          width="100%"
          viewBox="0 0 800 600"
          style={{
            position: 'absolute',
          }}
        >
          <path d="M 0 600 C 200 250 650 450 800 0 L 800 600 Z" fill="white" opacity="1" />
          <path d="M 0 600 C 250 300 650 350 800 0 L 800 600 Z" fill="white" opacity="1" />
          <path d="M 0 600 C 200 250 650 450 800 0 L 800 600 Z" fill="#54379d" opacity="0.8" />
          <path d="M 0 600 C 250 300 650 350 800 0 L 800 600 Z" fill="#54379d" opacity="0.8" />
        </svg>
      </div>
    );
  }
}
