// tslint:disable
/**
 * tenant
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    token: string;
}

export function TokenFromJSON(json: any): Token {
    return {
        'token': json['token'],
    };
}

export function TokenToJSON(value?: Token): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'token': value.token,
    };
}


