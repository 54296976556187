import React from 'react';
import classes from '../../utils/classes';
import { Classes, Tag, Intent } from '@blueprintjs/core';
import { DispatchReport } from '../../../sdks/internal-sdk';
import { historyDialog } from './History.scss';
import { mapBackendIdToDisplayName } from '../../utils/displayNames';

interface Props {
  report: DispatchReport;
}

export function DispatchDetails(props: Props) {
  return (
    <div className={historyDialog}>
      <h2>{props.report.notification.title}</h2>
      <div>{props.report.startedAt.toDateString()}</div>
      <pre>{props.report.notification.detail}</pre>
      <div>
        <table className={classes(Classes.HTML_TABLE, Classes.HTML_TABLE_STRIPED)}>
          <thead>
            <tr>
              <th>Transport</th>
              <th>Success</th>
            </tr>
          </thead>
          <tbody>
            {props.report.transportReports.map((transport) =>
              <tr key={mapBackendIdToDisplayName(transport.backendId)}>
                <td>
                  {mapBackendIdToDisplayName(transport.backendId)}
                </td>
                <td>
                  {transport.successful ? (
                    <Tag intent={Intent.SUCCESS} children="successful" minimal />
                  ) : (
                      <Tag intent={Intent.DANGER} children="failures" minimal />
                    )}
                </td>
                {!transport.successful &&
                  <td>
                    <pre>
                      {JSON.stringify(transport.failures, undefined, 2)}
                    </pre>
                  </td>
                }
              </tr>,
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
