import React from 'react';
import { userSelect, content } from './UserSelect.scss';
import { Button, Icon, Dialog, FormGroup, InputGroup, TagInput, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { isEqual } from 'lodash-es';

interface User {
  username: string;
  email?: string;
  phone?: string;
}

interface Props {
  value: User[];
  onChange: (users: User[]) => void | Promise<void>;
}

function emptyUser(): User {
  return {
    username: '',
    email: '',
    phone: '',
  };
}

@observer
export class UserSelect extends React.Component<Props> {

  state = {
    isOpen: false,
  };

  @observable
  private newUser = emptyUser();

  private onRemove = (value: string, index: number) => {
    const next = this.props.value.slice();
    next.splice(index, 1);
    this.props.onChange(next);
  }

  private onAdd = async () => {
    if (!isEqual(this.newUser, emptyUser())) {
      await this.props.onChange([...this.props.value, toJS(this.newUser)]);
      this.newUser = emptyUser();
      this.setState({ isOpen: false });
    }
  }

  render() {
    return (
      <div className={userSelect}>
        <TagInput
          values={this.props.value.map((value) => value.username)}
          inputProps={{ value: '' }}
          leftIcon={IconNames.USER}
          onRemove={this.onRemove}
          rightElement={
            <Button onClick={() => this.setState({ isOpen: true })}>
              <Icon icon={IconNames.PLUS} />
            </Button>
          }
        />
        <Dialog isOpen={this.state.isOpen} onClose={() => this.setState({ isOpen: false })}>
          <div className={content}>
            <FormGroup label="username" labelFor="username">
              <InputGroup
                id="username *"
                placeholder="username..."
                value={this.newUser.username}
                onChange={(event: any) => this.newUser.username = event.target.value}
              />
            </FormGroup>
            <FormGroup label="email" labelFor="email">
              <InputGroup
                id="email"
                placeholder="email..."
                value={this.newUser.email}
                onChange={(event: any) => this.newUser.email = event.target.value}
              />
            </FormGroup>
            <FormGroup label="phone" labelFor="phone">
              <InputGroup
                id="phone"
                placeholder="phone..."
                value={this.newUser.phone}
                onChange={(event: any) => this.newUser.phone = event.target.value}
              />
            </FormGroup>
            <Button
              onClick={this.onAdd}
              text="Add"
              intent={Intent.SUCCESS}
              type="submit"
            />
            <Button
              onClick={() => this.setState({ isOpen: false })}
              text="Cancel"
              intent={Intent.NONE}
              type="button"
            />
          </div>
        </Dialog>
      </div>
    );
  }
}
