/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */

export const ADD = "add";
export const ADD_COLUMN_LEFT = "add-column-left";
export const ADD_COLUMN_RIGHT = "add-column-right";
export const ADD_ROW_BOTTOM = "add-row-bottom";
export const ADD_ROW_TOP = "add-row-top";
export const ADD_TO_ARTIFACT = "add-to-artifact";
export const ADD_TO_FOLDER = "add-to-folder";
export const AIRPLANE = "airplane";
export const ALIGN_CENTER = "align-center";
export const ALIGN_JUSTIFY = "align-justify";
export const ALIGN_LEFT = "align-left";
export const ALIGN_RIGHT = "align-right";
export const ALIGNMENT_BOTTOM = "alignment-bottom";
export const ALIGNMENT_HORIZONTAL_CENTER = "alignment-horizontal-center";
export const ALIGNMENT_LEFT = "alignment-left";
export const ALIGNMENT_RIGHT = "alignment-right";
export const ALIGNMENT_TOP = "alignment-top";
export const ALIGNMENT_VERTICAL_CENTER = "alignment-vertical-center";
export const ANNOTATION = "annotation";
export const APPLICATION = "application";
export const APPLICATIONS = "applications";
export const ARROW_BOTTOM_LEFT = "arrow-bottom-left";
export const ARROW_BOTTOM_RIGHT = "arrow-bottom-right";
export const ARROW_DOWN = "arrow-down";
export const ARROW_LEFT = "arrow-left";
export const ARROW_RIGHT = "arrow-right";
export const ARROW_TOP_LEFT = "arrow-top-left";
export const ARROW_TOP_RIGHT = "arrow-top-right";
export const ARROW_UP = "arrow-up";
export const ARROWS_HORIZONTAL = "arrows-horizontal";
export const ARROWS_VERTICAL = "arrows-vertical";
export const ASTERISK = "asterisk";
export const AUTOMATIC_UPDATES = "automatic-updates";
export const BADGE = "badge";
export const BAN_CIRCLE = "ban-circle";
export const BANK_ACCOUNT = "bank-account";
export const BARCODE = "barcode";
export const BLANK = "blank";
export const BLOCKED_PERSON = "blocked-person";
export const BOLD = "bold";
export const BOOK = "book";
export const BOOKMARK = "bookmark";
export const BOX = "box";
export const BRIEFCASE = "briefcase";
export const BUILD = "build";
export const CALCULATOR = "calculator";
export const CALENDAR = "calendar";
export const CAMERA = "camera";
export const CARET_DOWN = "caret-down";
export const CARET_LEFT = "caret-left";
export const CARET_RIGHT = "caret-right";
export const CARET_UP = "caret-up";
export const CELL_TOWER = "cell-tower";
export const CHANGES = "changes";
export const CHART = "chart";
export const CHAT = "chat";
export const CHEVRON_BACKWARD = "chevron-backward";
export const CHEVRON_DOWN = "chevron-down";
export const CHEVRON_FORWARD = "chevron-forward";
export const CHEVRON_LEFT = "chevron-left";
export const CHEVRON_RIGHT = "chevron-right";
export const CHEVRON_UP = "chevron-up";
export const CIRCLE = "circle";
export const CIRCLE_ARROW_DOWN = "circle-arrow-down";
export const CIRCLE_ARROW_LEFT = "circle-arrow-left";
export const CIRCLE_ARROW_RIGHT = "circle-arrow-right";
export const CIRCLE_ARROW_UP = "circle-arrow-up";
export const CITATION = "citation";
export const CLEAN = "clean";
export const CLIPBOARD = "clipboard";
export const CLOUD = "cloud";
export const CLOUD_DOWNLOAD = "cloud-download";
export const CLOUD_UPLOAD = "cloud-upload";
export const CODE = "code";
export const CODE_BLOCK = "code-block";
export const COG = "cog";
export const COLLAPSE_ALL = "collapse-all";
export const COLUMN_LAYOUT = "column-layout";
export const COMMENT = "comment";
export const COMPARISON = "comparison";
export const COMPASS = "compass";
export const COMPRESSED = "compressed";
export const CONFIRM = "confirm";
export const CONSOLE = "console";
export const CONTRAST = "contrast";
export const CONTROL = "control";
export const CREDIT_CARD = "credit-card";
export const CROSS = "cross";
export const CROWN = "crown";
export const CUBE = "cube";
export const CUBE_ADD = "cube-add";
export const CUBE_REMOVE = "cube-remove";
export const CURVED_RANGE_CHART = "curved-range-chart";
export const CUT = "cut";
export const DASHBOARD = "dashboard";
export const DATABASE = "database";
export const DELETE = "delete";
export const DELTA = "delta";
export const DERIVE_COLUMN = "derive-column";
export const DESKTOP = "desktop";
export const DIAGRAM_TREE = "diagram-tree";
export const DIRECTION_LEFT = "direction-left";
export const DIRECTION_RIGHT = "direction-right";
export const DISABLE = "disable";
export const DOCUMENT = "document";
export const DOCUMENT_OPEN = "document-open";
export const DOCUMENT_SHARE = "document-share";
export const DOLLAR = "dollar";
export const DOT = "dot";
export const DOUBLE_CARET_HORIZONTAL = "double-caret-horizontal";
export const DOUBLE_CARET_VERTICAL = "double-caret-vertical";
export const DOUBLE_CHEVRON_DOWN = "double-chevron-down";
export const DOUBLE_CHEVRON_LEFT = "double-chevron-left";
export const DOUBLE_CHEVRON_RIGHT = "double-chevron-right";
export const DOUBLE_CHEVRON_UP = "double-chevron-up";
export const DOUGHNUT_CHART = "doughnut-chart";
export const DOWNLOAD = "download";
export const DRAG_HANDLE_HORIZONTAL = "drag-handle-horizontal";
export const DRAG_HANDLE_VERTICAL = "drag-handle-vertical";
export const DRAW = "draw";
export const DRIVE_TIME = "drive-time";
export const DUPLICATE = "duplicate";
export const EDIT = "edit";
export const EJECT = "eject";
export const ENDORSED = "endorsed";
export const ENVELOPE = "envelope";
export const ERASER = "eraser";
export const ERROR = "error";
export const EURO = "euro";
export const EXCHANGE = "exchange";
export const EXCLUDE_ROW = "exclude-row";
export const EXPAND_ALL = "expand-all";
export const EXPORT = "export";
export const EYE_OFF = "eye-off";
export const EYE_ON = "eye-on";
export const EYE_OPEN = "eye-open";
export const FAST_BACKWARD = "fast-backward";
export const FAST_FORWARD = "fast-forward";
export const FEED = "feed";
export const FEED_SUBSCRIBED = "feed-subscribed";
export const FILM = "film";
export const FILTER = "filter";
export const FILTER_KEEP = "filter-keep";
export const FILTER_LIST = "filter-list";
export const FILTER_REMOVE = "filter-remove";
export const FLAG = "flag";
export const FLAME = "flame";
export const FLASH = "flash";
export const FLOPPY_DISK = "floppy-disk";
export const FLOW_BRANCH = "flow-branch";
export const FLOW_END = "flow-end";
export const FLOW_LINEAR = "flow-linear";
export const FLOW_REVIEW = "flow-review";
export const FLOW_REVIEW_BRANCH = "flow-review-branch";
export const FLOWS = "flows";
export const FOLDER_CLOSE = "folder-close";
export const FOLDER_NEW = "folder-new";
export const FOLDER_OPEN = "folder-open";
export const FOLDER_SHARED = "folder-shared";
export const FOLDER_SHARED_OPEN = "folder-shared-open";
export const FOLLOWER = "follower";
export const FOLLOWING = "following";
export const FONT = "font";
export const FORK = "fork";
export const FORM = "form";
export const FULL_CIRCLE = "full-circle";
export const FULL_STACKED_CHART = "full-stacked-chart";
export const FULLSCREEN = "fullscreen";
export const FUNCTION = "function";
export const GANTT_CHART = "gantt-chart";
export const GEOLOCATION = "geolocation";
export const GEOSEARCH = "geosearch";
export const GIT_BRANCH = "git-branch";
export const GIT_COMMIT = "git-commit";
export const GIT_MERGE = "git-merge";
export const GIT_NEW_BRANCH = "git-new-branch";
export const GIT_PULL = "git-pull";
export const GIT_PUSH = "git-push";
export const GIT_REPO = "git-repo";
export const GLASS = "glass";
export const GLOBE = "globe";
export const GLOBE_NETWORK = "globe-network";
export const GRAPH = "graph";
export const GRAPH_REMOVE = "graph-remove";
export const GRID = "grid";
export const GRID_VIEW = "grid-view";
export const GROUP_OBJECTS = "group-objects";
export const GROUPED_BAR_CHART = "grouped-bar-chart";
export const HAND = "hand";
export const HAND_DOWN = "hand-down";
export const HAND_LEFT = "hand-left";
export const HAND_RIGHT = "hand-right";
export const HAND_UP = "hand-up";
export const HEADER = "header";
export const HEADER_ONE = "header-one";
export const HEADER_TWO = "header-two";
export const HEADSET = "headset";
export const HEART = "heart";
export const HEART_BROKEN = "heart-broken";
export const HEAT_GRID = "heat-grid";
export const HEATMAP = "heatmap";
export const HELP = "help";
export const HELPER_MANAGEMENT = "helper-management";
export const HIGHLIGHT = "highlight";
export const HISTORY = "history";
export const HOME = "home";
export const HORIZONTAL_BAR_CHART = "horizontal-bar-chart";
export const HORIZONTAL_BAR_CHART_ASC = "horizontal-bar-chart-asc";
export const HORIZONTAL_BAR_CHART_DESC = "horizontal-bar-chart-desc";
export const HORIZONTAL_DISTRIBUTION = "horizontal-distribution";
export const ID_NUMBER = "id-number";
export const IMAGE_ROTATE_LEFT = "image-rotate-left";
export const IMAGE_ROTATE_RIGHT = "image-rotate-right";
export const IMPORT = "import";
export const INBOX = "inbox";
export const INBOX_FILTERED = "inbox-filtered";
export const INBOX_GEO = "inbox-geo";
export const INBOX_SEARCH = "inbox-search";
export const INBOX_UPDATE = "inbox-update";
export const INFO_SIGN = "info-sign";
export const INNER_JOIN = "inner-join";
export const INSERT = "insert";
export const INTERSECTION = "intersection";
export const IP_ADDRESS = "ip-address";
export const ISSUE = "issue";
export const ISSUE_CLOSED = "issue-closed";
export const ISSUE_NEW = "issue-new";
export const ITALIC = "italic";
export const JOIN_TABLE = "join-table";
export const KEY = "key";
export const KEY_BACKSPACE = "key-backspace";
export const KEY_COMMAND = "key-command";
export const KEY_CONTROL = "key-control";
export const KEY_DELETE = "key-delete";
export const KEY_ENTER = "key-enter";
export const KEY_ESCAPE = "key-escape";
export const KEY_OPTION = "key-option";
export const KEY_SHIFT = "key-shift";
export const KEY_TAB = "key-tab";
export const KNOWN_VEHICLE = "known-vehicle";
export const LABEL = "label";
export const LAYER = "layer";
export const LAYERS = "layers";
export const LAYOUT = "layout";
export const LAYOUT_AUTO = "layout-auto";
export const LAYOUT_BALLOON = "layout-balloon";
export const LAYOUT_CIRCLE = "layout-circle";
export const LAYOUT_GRID = "layout-grid";
export const LAYOUT_GROUP_BY = "layout-group-by";
export const LAYOUT_HIERARCHY = "layout-hierarchy";
export const LAYOUT_LINEAR = "layout-linear";
export const LAYOUT_SKEW_GRID = "layout-skew-grid";
export const LAYOUT_SORTED_CLUSTERS = "layout-sorted-clusters";
export const LEFT_JOIN = "left-join";
export const LIFESAVER = "lifesaver";
export const LIGHTBULB = "lightbulb";
export const LINK = "link";
export const LIST = "list";
export const LIST_COLUMNS = "list-columns";
export const LIST_DETAIL_VIEW = "list-detail-view";
export const LOCATE = "locate";
export const LOCK = "lock";
export const LOG_IN = "log-in";
export const LOG_OUT = "log-out";
export const MANUAL = "manual";
export const MANUALLY_ENTERED_DATA = "manually-entered-data";
export const MAP = "map";
export const MAP_CREATE = "map-create";
export const MAP_MARKER = "map-marker";
export const MAXIMIZE = "maximize";
export const MEDIA = "media";
export const MENU = "menu";
export const MENU_CLOSED = "menu-closed";
export const MENU_OPEN = "menu-open";
export const MERGE_COLUMNS = "merge-columns";
export const MERGE_LINKS = "merge-links";
export const MINIMIZE = "minimize";
export const MINUS = "minus";
export const MOBILE_PHONE = "mobile-phone";
export const MOBILE_VIDEO = "mobile-video";
export const MOON = "moon";
export const MORE = "more";
export const MOUNTAIN = "mountain";
export const MOVE = "move";
export const MUGSHOT = "mugshot";
export const MULTI_SELECT = "multi-select";
export const MUSIC = "music";
export const NEW_GRID_ITEM = "new-grid-item";
export const NEW_LINK = "new-link";
export const NEW_OBJECT = "new-object";
export const NEW_PERSON = "new-person";
export const NEW_PRESCRIPTION = "new-prescription";
export const NEW_TEXT_BOX = "new-text-box";
export const NINJA = "ninja";
export const NOTIFICATIONS = "notifications";
export const NOTIFICATIONS_UPDATED = "notifications-updated";
export const NUMBERED_LIST = "numbered-list";
export const NUMERICAL = "numerical";
export const OFFICE = "office";
export const OFFLINE = "offline";
export const OIL_FIELD = "oil-field";
export const ONE_COLUMN = "one-column";
export const OUTDATED = "outdated";
export const PAGE_LAYOUT = "page-layout";
export const PANEL_STATS = "panel-stats";
export const PANEL_TABLE = "panel-table";
export const PAPERCLIP = "paperclip";
export const PARAGRAPH = "paragraph";
export const PATH = "path";
export const PATH_SEARCH = "path-search";
export const PAUSE = "pause";
export const PEOPLE = "people";
export const PERCENTAGE = "percentage";
export const PERSON = "person";
export const PHONE = "phone";
export const PIE_CHART = "pie-chart";
export const PIN = "pin";
export const PIVOT = "pivot";
export const PIVOT_TABLE = "pivot-table";
export const PLAY = "play";
export const PLUS = "plus";
export const POLYGON_FILTER = "polygon-filter";
export const POWER = "power";
export const PREDICTIVE_ANALYSIS = "predictive-analysis";
export const PRESCRIPTION = "prescription";
export const PRESENTATION = "presentation";
export const PRINT = "print";
export const PROJECTS = "projects";
export const PROPERTIES = "properties";
export const PROPERTY = "property";
export const PUBLISH_FUNCTION = "publish-function";
export const PULSE = "pulse";
export const RANDOM = "random";
export const RECORD = "record";
export const REDO = "redo";
export const REFRESH = "refresh";
export const REGRESSION_CHART = "regression-chart";
export const REMOVE = "remove";
export const REMOVE_COLUMN = "remove-column";
export const REMOVE_COLUMN_LEFT = "remove-column-left";
export const REMOVE_COLUMN_RIGHT = "remove-column-right";
export const REMOVE_ROW_BOTTOM = "remove-row-bottom";
export const REMOVE_ROW_TOP = "remove-row-top";
export const REPEAT = "repeat";
export const RESOLVE = "resolve";
export const RIG = "rig";
export const RIGHT_JOIN = "right-join";
export const RING = "ring";
export const ROTATE_DOCUMENT = "rotate-document";
export const ROTATE_PAGE = "rotate-page";
export const SATELLITE = "satellite";
export const SAVED = "saved";
export const SCATTER_PLOT = "scatter-plot";
export const SEARCH = "search";
export const SEARCH_AROUND = "search-around";
export const SEARCH_TEMPLATE = "search-template";
export const SEARCH_TEXT = "search-text";
export const SEGMENTED_CONTROL = "segmented-control";
export const SELECT = "select";
export const SELECTION = "selection";
export const SEND_TO = "send-to";
export const SEND_TO_GRAPH = "send-to-graph";
export const SEND_TO_MAP = "send-to-map";
export const SERIES_ADD = "series-add";
export const SERIES_CONFIGURATION = "series-configuration";
export const SERIES_DERIVED = "series-derived";
export const SERIES_FILTERED = "series-filtered";
export const SERIES_SEARCH = "series-search";
export const SETTINGS = "settings";
export const SHARE = "share";
export const SHIELD = "shield";
export const SHOP = "shop";
export const SHOPPING_CART = "shopping-cart";
export const SIM_CARD = "sim-card";
export const SLASH = "slash";
export const SMALL_CROSS = "small-cross";
export const SMALL_MINUS = "small-minus";
export const SMALL_PLUS = "small-plus";
export const SMALL_TICK = "small-tick";
export const SNOWFLAKE = "snowflake";
export const SOCIAL_MEDIA = "social-media";
export const SORT = "sort";
export const SORT_ALPHABETICAL = "sort-alphabetical";
export const SORT_ALPHABETICAL_DESC = "sort-alphabetical-desc";
export const SORT_ASC = "sort-asc";
export const SORT_DESC = "sort-desc";
export const SORT_NUMERICAL = "sort-numerical";
export const SORT_NUMERICAL_DESC = "sort-numerical-desc";
export const SPLIT_COLUMNS = "split-columns";
export const SQUARE = "square";
export const STACKED_CHART = "stacked-chart";
export const STAR = "star";
export const STAR_EMPTY = "star-empty";
export const STEP_BACKWARD = "step-backward";
export const STEP_CHART = "step-chart";
export const STEP_FORWARD = "step-forward";
export const STOP = "stop";
export const STRIKETHROUGH = "strikethrough";
export const STYLE = "style";
export const SWAP_HORIZONTAL = "swap-horizontal";
export const SWAP_VERTICAL = "swap-vertical";
export const SYMBOL_CIRCLE = "symbol-circle";
export const SYMBOL_CROSS = "symbol-cross";
export const SYMBOL_DIAMOND = "symbol-diamond";
export const SYMBOL_SQUARE = "symbol-square";
export const SYMBOL_TRIANGLE_DOWN = "symbol-triangle-down";
export const SYMBOL_TRIANGLE_UP = "symbol-triangle-up";
export const TAG = "tag";
export const TAKE_ACTION = "take-action";
export const TAXI = "taxi";
export const TEXT_HIGHLIGHT = "text-highlight";
export const TH = "th";
export const TH_DERIVED = "th-derived";
export const TH_FILTERED = "th-filtered";
export const TH_LIST = "th-list";
export const THUMBS_DOWN = "thumbs-down";
export const THUMBS_UP = "thumbs-up";
export const TICK = "tick";
export const TICK_CIRCLE = "tick-circle";
export const TIME = "time";
export const TIMELINE_AREA_CHART = "timeline-area-chart";
export const TIMELINE_BAR_CHART = "timeline-bar-chart";
export const TIMELINE_EVENTS = "timeline-events";
export const TIMELINE_LINE_CHART = "timeline-line-chart";
export const TINT = "tint";
export const TORCH = "torch";
export const TRAIN = "train";
export const TRANSLATE = "translate";
export const TRASH = "trash";
export const TREE = "tree";
export const TRENDING_DOWN = "trending-down";
export const TRENDING_UP = "trending-up";
export const TWO_COLUMNS = "two-columns";
export const UNDERLINE = "underline";
export const UNDO = "undo";
export const UNGROUP_OBJECTS = "ungroup-objects";
export const UNKNOWN_VEHICLE = "unknown-vehicle";
export const UNLOCK = "unlock";
export const UNPIN = "unpin";
export const UNRESOLVE = "unresolve";
export const UPDATED = "updated";
export const UPLOAD = "upload";
export const USER = "user";
export const VARIABLE = "variable";
export const VERTICAL_BAR_CHART_ASC = "vertical-bar-chart-asc";
export const VERTICAL_BAR_CHART_DESC = "vertical-bar-chart-desc";
export const VERTICAL_DISTRIBUTION = "vertical-distribution";
export const VIDEO = "video";
export const VOLUME_DOWN = "volume-down";
export const VOLUME_OFF = "volume-off";
export const VOLUME_UP = "volume-up";
export const WALK = "walk";
export const WARNING_SIGN = "warning-sign";
export const WATERFALL_CHART = "waterfall-chart";
export const WIDGET = "widget";
export const WIDGET_BUTTON = "widget-button";
export const WIDGET_FOOTER = "widget-footer";
export const WIDGET_HEADER = "widget-header";
export const WRENCH = "wrench";
export const ZOOM_IN = "zoom-in";
export const ZOOM_OUT = "zoom-out";
export const ZOOM_TO_FIT = "zoom-to-fit";
