import React from 'react';
import { apiKeyTable } from './ApiKeyTable.scss';
import classes from '../../utils/classes';
import { Classes, Button, Intent, InputGroup } from '@blueprintjs/core';
import { RevealableValue } from '../revealable-value/RevealableValue';
import { IconNames } from '@blueprintjs/icons';
import { confirmDangerousAction } from '../prompt/Prompt';
import { row, justifyEnd } from '../../common.scss';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Form } from '../form/Form';
import { AsyncActionButton } from '../async-action-button/AsyncActionButton';
import { ApiKey, CreateApiKey } from '../../../sdks/internal-sdk';

interface Props {
  keys: ApiKey[];
  onKeyDelete: (key: ApiKey) => void;
  onKeyCreate: (key: CreateApiKey) => void | Promise<void>;
}

@observer
export class ApiKeyTable extends React.Component<Props> {

  @observable
  private newKeyComment = '';

  private onSubmit = async () => {
    await this.props.onKeyCreate({ comment: this.newKeyComment });
    this.newKeyComment = '';
  }

  render() {
    return (
      <div className={apiKeyTable}>
        <Form>
          <table className={classes(Classes.HTML_TABLE, Classes.HTML_TABLE_STRIPED)}>
            <thead>
              <tr>
                <th>Created On</th>
                <th>Comment</th>
                <th>Key</th>
              </tr>
            </thead>
            <tbody>
              {this.props.keys.map((apikey) =>
                <tr key={apikey.id}>
                  <td>{apikey.createdAt}</td>
                  <td>{apikey.comment}</td>
                  <td>
                    <RevealableValue value={apikey.key} />
                  </td>
                  <td>
                    <Button
                      icon={IconNames.CROSS}
                      intent={Intent.DANGER}
                      onClick={async () => {
                        if (await confirmDangerousAction('Are you sure you want to delete this API Key?')) {
                          this.props.onKeyDelete(apikey);
                        }
                      }}
                    />
                  </td>
                </tr>,
              )}
              <tr>
                <td>
                  <strong>
                    Create a key:
                    </strong>
                </td>
                <td colSpan={2}>
                  <div className={classes(row, justifyEnd)}>
                    <InputGroup
                      autoComplete="off"
                      placeholder="comment..."
                      value={this.newKeyComment}
                      onChange={(event: any) => this.newKeyComment = event.target.value}
                      required
                    />
                  </div>
                </td>
                <td>
                  <AsyncActionButton
                    onClick={this.onSubmit}
                    icon={IconNames.PLUS}
                    intent={Intent.SUCCESS}
                    type="submit"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Form>
      </div>
    );
  }
}
