import React from 'react';
import AsyncContent from './AsyncContent';

export interface AsyncPropsTransformer {
  (props: any): Promise<void>;
}

export function preload(
  callback: AsyncPropsTransformer,
  errorMessage: React.ReactNode = undefined,
  placeholder: React.ReactNode = undefined,
) {
  // tslint:disable-next-line:variable-name
  function preloadDecorator<T>(Component: React.ComponentClass<T>): any {
    return class PreloadHOC extends React.Component<T> {
      render() {
        return (
          <AsyncContent
            errorMessage={errorMessage}
            placeholder={placeholder}
          >
            {async () => {
              await callback(this.props);
              return <Component {...this.props} />;
            }}
          </AsyncContent>
        );
      }
    };
  }
  return preloadDecorator;
}
